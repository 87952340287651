import { isNullOrUndefined } from "@rivial-security/func-utils";
import React, { useContext, useEffect, useState } from "react";

import { fieldContexts } from "../../../enums/fieldContexts";
import { useBoolean } from "../../../hooks/functional/useBoolean";
import { useMutation } from "../../../hooks/graphql/useMutation/useMutation";
import { useCheckPermissions } from "../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { UIContext } from "../../Context/UIContext";
import { ErrorLogger } from "../../EventLogger";
import PermissionsOverlay from "../../Overlays/PermissionsOverlay";

import { GENERIC_FIELD_TYPES } from "./constants/GENERIC_FIELD_TYPES";
import { handleButtons } from "./functions/handleButtons";
import { handleTooltip } from "./functions/handleTooltip";
import { handleUI } from "./functions/handleUI";
import { saveChange } from "./functions/saveChange/saveChange";

/**
 * A Generic Field to be used for database edits.
 * @param {object} props
 *
 * @param {object} props.item - the item to be updated
 * @param {string} props.item.id - the ID of the item to be updated
 * @param {function} [props.confirmFunction] - function to confirm the update
 * @param {string} props.field - the field that is being updated
 * @param {string} props.module - the module that this field lives in
 * @param {string} props.resource - the resource that this field belongs to
 * @param {string} [props.mutation] - the mutation string to be used for this DB operation
 * @param {object} [props.displayConfig] - the display configuration for this field
 * @param {function} [props.mutationFunction] - takes priority over the built-in mutation, for custom mutation behavior
 * (see LossExceedanceTolerance.js for an example)
 * @param {boolean} [props.disableRoleChecking = false] - disables role checking completely
 * @param {boolean|function} [props.disableEdits = false] - disables editing functionality completely, optionally a function which takes in the item
 * @param {string} [props.inputType = text]
 * @param {object} [props.inputConfig] - input configuration
 * @param {function} [props.customFormat] - updates the value state of the field for the UI, but not for the mutation
 * @param {function} [props.displayFormat] - same as customFormat but the formatting is removed when editing a field (takes precedence over customFormat)
 * @param {JSX.Element} [props.customInputComponent] - overrides the Input Type Input component
 * @param {JSX.Element} [props.customDisplayComponent] - overrides the Input Type Display component
 * @param {boolean} [props.updateInputOnlyForMutation] - if TRUE finalValue will be reverted to the the one before
 * updateInputFunction was executed (after the mutation has finished)
 * @param {function} [props.updateInputFunction] - a function that updates the field input before the mutation
 * @param {function} [props.updateMutationFunction] - updates the whole mutation object right before the DB operation
 * @param {function} [props.updateItemById] - update an item by id in the grid
 * @param {boolean} [props.forceLowercase] - forces the field input to be all lowercase
 * @param {string} [props.typename] - parent type name
 * @param {function} [props.resetFunction] - reset function from parent component
 * @param {string} [props.friendlyName] - an optionally field name override for display purposes
 * @param {JSX.Element} [props.deleteButton] - optionally render a delete button next to the edit button
 * @param {string} [props.tooltip] - optional, adds a tooltip to the right of the field buttons
 * @param {object} [props.warning] - optional, adds a warning icon at the end of the field buttons with a tooltip displaying the warning message, object passed down into WarningTooltip
 * @param {boolean} [props.defaultOpen] - optional, if true, the field input will be open by default
 * @param {function} [props.onChange] - optional, callback function that is called when the field input is changed
 * @param {function} [props.submitFunction] - bypasses the default mutation and uses this function instead
 * @param {JSX.Element} [props.editSettingsComponent] - optional, component to be displayed as edit settings, when field is in edit state
 * @param {string} [props.fieldContext] - the placement of the field (grid, details, etc.)
 * @param {function} [props.renderAddonElement] - function which optionally renders an additional element on the bottom of the field
 * @param {boolean} [props.allowEmpty = true] - if false, empty values will revert to the original value on save or cancel
 * @returns {JSX.Element}
 *
 * @example
 * <GenericEditField
 *    item={ { id: "123", foobar: "cool value" } }
 *    field="foobar"
 *    disableRoleChecking={true}
 *    mutation={updateResourceMutation}
 * />
 */
const GenericEditFieldV3 = ({
  customInputComponent,
  customDisplayComponent,
  customFormat,
  confirmFunction,
  defaultOpen = false,
  deleteButton,
  disableEdits,
  disableRoleChecking,
  displayConfig = {},
  displayFormat,
  field,
  forceLowercase = false,
  friendlyName,
  inputType = GENERIC_FIELD_TYPES.TEXT,
  inputConfig = {},
  item = {},
  module,
  mutation,
  mutationFunction,
  onChange,
  resetFunction,
  resource,
  submitFunction,
  typename,
  tooltip,
  updateInputFunction,
  updateInputOnlyForMutation = false,
  updateItemById,
  updateMutationFunction,
  warning,
  editSettingsComponent,
  fieldContext = fieldContexts.STANDALONE,
  renderAddonElement,
  allowEmpty = true,
}) => {
  /**
   * Determines whether to display the Input Component or Display Component,
   * as well as which Buttons to show, TRUE = Input, FALSE = Display
   */
  const [toggleEdit, setToggleEdit] = useState(defaultOpen);

  /**
   * The current Value of the edit field.
   */
  const [value, setValue] = useState(item[field]);

  /**
   * Saves the originalValue state, for when the User cancels an edit
   */
  const [originalValue, setOriginalValue] = useState(value);

  /**
   * A custom alert message
   */
  const [alertMessage, setAlertMessage] = useState(null);

  /**
   * If the field is hovered or not, used for edit buttons
   */
  const [isHovered, setIsHovered] = useBoolean(false);

  /**
   * Toggles the state of the field to display the Input Component or the Display Component
   * @param e
   */
  const toggle = async (e) => {
    e.stopPropagation();

    if (toggleEdit) {
      try {
        //Check with the confirm function if the change should be applied
        if (!isNullOrUndefined(confirmFunction) && confirmFunction(value) === false) {
          setValue(item[field]);
          return;
        }

        // Handle empty value case when not allowed
        if (!allowEmpty && value === "") {
          setValue(originalValue);
          setToggleEdit(false);
          setIsHovered(false);
          return;
        }

        if (typeof submitFunction === "function") {
          await submitFunction(context);
        } else {
          await saveChange(context);
        }
      } catch (e) {
        ErrorLogger("Error: Could not save the change!", e);
      }
    } else {
      if (displayFormat) {
        setValue(item[field]);
      }
    }

    setToggleEdit((toggleEdit) => !toggleEdit);
    setIsHovered(false);
  };

  /**
   * Sets the Edit state back to false in order to display the Display Component
   * @param e
   */
  const cancel = (e) => {
    e.stopPropagation();
    setToggleEdit(false);

    if (displayFormat && typeof displayFormat === "function") {
      setValue(displayFormat(item[field]));
    } else if (customFormat && typeof customFormat === "function") {
      setValue(customFormat(item[field]));
    } else {
      setValue(item[field]);
    }
  };

  /**
   * Used to display toast messages to the user about the Edit
   */
  const { addToast, updateToast } = useContext(UIContext);

  /**
   * For mutating the item in the database when an Edit has been confirmed
   */
  const updateMutationHook = useMutation({
    module,
    resource,
    mutation,
    field,
    disableRoleChecking: disableRoleChecking ? disableRoleChecking : undefined,
    friendlyName,
  });

  /**
   * Determines if the user has Update permission for this field
   */
  const updatePermission = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking: disableRoleChecking ? disableRoleChecking : undefined,
  }).resource.update;

  /**
   * Bundles up all of the proper variables and functions to send downstream
   *
   * @typedef {object} GenericEditFieldContext
   *
   * @property {object} item - the database item that is being edited
   * @property {string} field - the field that is being edited
   * @property {boolean} toggleEdit - if this field is being edited
   * @property {string|number|boolean} value - the current value of the field
   * @property {GENERIC_FIELD_TYPES} inputType - the Input type for this field
   * @property {string|number|boolean} originalValue - the original value of this field
   * @property {function} setOriginalValue - sets the original value of this field
   * @property {function} setValue - sets the current value of this field
   * @property {setToggleEdit} setToggleEdit - sets this field to be edited or not
   * @property {string} alertMessage - an alert message to display?
   * @property {function} setAlertMessage - sets the alert message text
   * @property {function} cancel - cancels the edit, sets the value back to original value
   * @property {function} saveChange - saves the edit to the database
   * @property {function} toggle
   * @property {function} addToast
   * @property {function} updateToast
   * @property {object} updateMutationHook
   * @property {boolean} disableEdits
   * @property {boolean} updatePermission
   * @property {string} mutation
   * @property {JSX.Element} customInputComponent
   * @property {JSX.Element} customDisplayComponent
   * @property {string} fieldContext
   */
  const context = {
    item,
    field,
    toggleEdit,
    value,
    inputType,
    inputConfig,
    isHovered,
    setIsHovered,
    originalValue,
    setOriginalValue,
    setValue,
    setToggleEdit,
    alertMessage,
    setAlertMessage,
    cancel,
    saveChange,
    toggle,
    addToast,
    updateToast,
    updateMutationHook,
    disableEdits,
    updatePermission,
    mutation,
    mutationFunction,
    customInputComponent,
    customDisplayComponent,
    customFormat,
    confirmFunction,
    updateInputOnlyForMutation,
    updateInputFunction,
    updateMutationFunction,
    updateItemById,
    forceLowercase,
    typename,
    resetFunction,
    friendlyName,
    deleteButton,
    tooltip,
    warning,
    displayConfig,
    editSettingsComponent,
    fieldContext,
  };

  /**
   * Re-sets the 'value' when the parent item gets changed.
   *
   * If a customFormat function is passed in,
   * this is where it's used to mutate the field.
   */
  useEffect(() => {
    //Do not set a new value when data is not available
    if (!item || !item.hasOwnProperty(field)) {
      return;
    }

    //Do not set a new value when the field is being saved
    if (toggleEdit) {
      return;
    }

    if (displayFormat && typeof displayFormat === "function") {
      setValue(displayFormat(item[field]));
    } else if (customFormat && typeof customFormat === "function") {
      setValue(customFormat(item[field]));
    } else {
      //No need to set state when new value has the same content
      if (item[field] === value) {
        return;
      }

      setValue(item[field]);

      if (typeof onChange === "function") {
        onChange(item[field]);
      }
    }
  }, [JSON.stringify(item)]);

  return (
    <PermissionsOverlay {...{ module, resource, field, disableRoleChecking }}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ width: "100%" }}
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
      >
        <span>{handleUI(context)}</span>
        {handleButtons(context)}
        {fieldContext !== fieldContexts.GRID && handleTooltip(context)}
        {renderAddonElement?.()}
      </div>
    </PermissionsOverlay>
  );
};

export default GenericEditFieldV3;
