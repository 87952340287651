import type { PropsWithChildren, ReactElement, ReactNode } from "react";

import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import type { DrawerSize } from "../../Templates/Drawer";

export interface DrawerRoute {
  id: string;
  title: string;
  isPinnable?: boolean;
  size?: DrawerSize;
  Element?: ReactNode;
}

export interface DrawerState {
  top: DrawerRoute | undefined;
  stack: DrawerRoute[];
  bottom: DrawerRoute | undefined;
}
export interface DrawerActions {
  push: (drawer: DrawerRoute) => void;
  pop: VoidFunction;
  popAll: VoidFunction;
  getTop: (stack: DrawerRoute[]) => DrawerRoute | undefined;
  goToFirst: VoidFunction;
  replaceAll: (drawer: DrawerRoute) => void;
}

export interface DrawerContextParameters {
  defaultStack?: DrawerRoute[];
}

export type DrawerProviderProps = PropsWithChildren<DrawerContextParameters>;

export const DrawerStateContext = createContext<DrawerState | undefined>(undefined);
export const DrawerActionsContext = createContext<DrawerActions | undefined>(undefined);

export const DrawerProvider = ({ defaultStack = [], children }: DrawerProviderProps): ReactElement => {
  const [stack, setStack] = useState<DrawerRoute[]>(defaultStack);

  const push = useCallback((drawer: DrawerRoute): void => {
    setStack((prevStack) => [...prevStack, drawer]);
  }, []);

  const pop = useCallback((): void => {
    setStack((prevStack) => prevStack.slice(0, -1));
  }, []);

  const popAll = useCallback((): void => {
    setStack([]);
  }, []);

  const getTop = useCallback((prevStack: DrawerRoute[]): DrawerRoute | undefined => {
    return prevStack[prevStack.length - 1];
  }, []);

  const goToFirst = useCallback((): void => {
    setStack((prevStack) => {
      if (prevStack.length === 0) return prevStack;
      return prevStack.slice(0, 1);
    });
  }, []);

  const replaceAll = useCallback((drawer: DrawerRoute): void => {
    setStack([drawer]);
  }, []);

  //Close all active drawers on route change
  const history = useHistory();

  useEffect(() => {
    const unlisten = history?.listen(() => {
      popAll();
    });

    return (): void => {
      unlisten?.();
    };
  }, [history]);

  const actions = useMemo(
    () => ({
      push,
      pop,
      popAll,
      getTop,
      goToFirst,
      replaceAll,
    }),
    [push, pop, popAll, getTop, goToFirst, replaceAll],
  );

  const state = useMemo(() => ({ top: stack[stack.length - 1], stack, bottom: stack[0] }), [stack]);

  return (
    <DrawerActionsContext.Provider value={actions}>
      <DrawerStateContext.Provider value={state}>{children}</DrawerStateContext.Provider>
    </DrawerActionsContext.Provider>
  );
};
