export interface ApplyConstraintsParams {
  value: string;
  isNumeric: boolean;
  minLimit?: number;
  maxLimit?: number;
  step?: number;
}

/**
 * Based on the constraints determine at start formats the text values
 */
export const applyConstraints = ({
  value,
  isNumeric,
  minLimit,
  maxLimit,
  step,
}: ApplyConstraintsParams): number | string => {
  if (!isNumeric || value === "") {
    return value;
  }

  const numberValue = parseFloat(value);
  if (isNaN(numberValue)) {
    return value;
  }

  try {
    let result = numberValue;
    let maxDecimals = 0;

    if (minLimit) {
      maxDecimals = Math.max(maxDecimals, (minLimit.toString().split(".")[1] || "").length);

      result = Math.max(minLimit, result);
    }
    if (maxLimit) {
      maxDecimals = Math.max(maxDecimals, (maxLimit.toString().split(".")[1] || "").length);

      result = Math.min(maxLimit, result);
    }
    if (step) {
      maxDecimals = Math.max(maxDecimals, (step.toString().split(".")[1] || "").length);

      result = Math.round(result / step) * step;
    }

    return Number(result.toFixed(maxDecimals));
  } catch {
    return value;
  }
};
