import type { ReactElement } from "react";

import { modules, resources } from "@rivial-security/role-utils";
import type { GenericActionItem } from "@rivial-security/schema-types";

import PointOfContactField from "@utils/CustomFields/PointOfContactField/PointOfContactField";

/**
 * Displays the Point of Contact for an Action Item.
 * Allows the User to adjust this field.
 */
const ActionPointOfContact = ({ item }: { item?: GenericActionItem }): ReactElement => {
  const module = modules.GOVERNANCE;
  const resource = resources.ACTION_ITEM;
  const typename = resources.ACTION_ITEM;
  const field = "pointOfContact";
  const idField = "genericActionItemPointOfContactId";

  return (
    <PointOfContactField
      item={item}
      module={module}
      resource={resource}
      typename={typename}
      field={field}
      idField={idField}
    />
  );
};

export default ActionPointOfContact;
