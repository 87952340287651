import type { ReactElement } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import PointOfContactField from "@utils/CustomFields/PointOfContactField/PointOfContactField";
import { getActionItemFormFields } from "@views/Program/Actions/hooks/useCreateAction/function/getActionItemFormFields";

export interface TargetVulnerabilityPointOfContactFieldProps {
  item?: { vulnerabilityName?: string; targetIp?: string; priority?: number; vulnerability?: { solution?: string } };
  getMutationID?: ({ item }: { item: Record<string, unknown> }) => string;
  getDefaultActionItemValues?: ({ row }) => Record<string, unknown>;
}

const TargetVulnerabilityPointOfContactField = ({
  item,
  getMutationID,
  getDefaultActionItemValues,
}: TargetVulnerabilityPointOfContactFieldProps): ReactElement => {
  return (
    <PointOfContactField
      item={item}
      module={modules.VULNERABILITIES}
      resource={resources.VULNERABILITY}
      typename={"TargetFindingLink"}
      field={"pointOfContact"}
      idField={"pointOfContactID"}
      additionalFormSteps={[
        {
          id: "create-action-item",
          stepName: "Add Action Item Details",
          fields: getActionItemFormFields({
            defaultValues: getDefaultActionItemValues?.({ row: item }),
            hiddenFields: ["genericActionItemPointOfContactId"],
          }),
        },
      ]}
      getMutationID={getMutationID}
      formatMutationInput={({ mutationInput, formInput }) => {
        return {
          ...mutationInput,
          lastActionItemSettings: JSON.stringify({
            ...(formInput ?? {}),
            epochTime: Date.now(),
          }),
        };
      }}
    />
  );
};

export default TargetVulnerabilityPointOfContactField;
