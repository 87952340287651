import React from "react";

import { NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

import type { NavigationModule } from "@application/Navigation/types";

const CustomQueryDashboard = React.lazy(() => import("@views/CustomQueries/components/CustomQueryDashboard"));
const CustomWidgetDashboard = React.lazy(() => import("@views/CustomWidgets/WidgetDashboard/CustomWidgetDashboard"));

const CustomQueryDetails = React.lazy(() => import("@views/CustomQueries/components/CustomQueryDetails"));
const CustomWidgetDetails = React.lazy(
  () => import("@views/CustomWidgets/WidgetDetails/components/CustomWidgetDetails"),
);

const ReportDashboard = React.lazy(() => import("@views/Reports/ReportsDashboard"));
const ReportDetails = React.lazy(() => import("@views/Reports/ReportDetails"));
const CreateReport = React.lazy(() => import("@views/Reports/CreateReport"));

export const reportsNavigation: NavigationModule = {
  moduleName: modules.REPORTS,
  sectionName: NavigationSectionName.TOOLS,
  name: "Reports",
  path: "/reports",
  defaultRoutePath: "/list",
  icon: IconName.NOTE,
  routes: [
    //Custom Queries and Widgets
    {
      path: "/queries",
      name: `Custom Queries`,
      navigationBar: {
        name: "Queries",
      },
      requiredModules: [modules.ORGANIZATION_MANAGER],
      component: CustomQueryDashboard,
    },
    {
      path: "/query/:id",
      name: "Custom Query Details",
      requiredModules: [modules.ORGANIZATION_MANAGER],
      component: CustomQueryDetails,
    },
    {
      path: "/widgets",
      name: `Custom Widgets`,
      requiredModules: [modules.ORGANIZATION_MANAGER],
      component: CustomWidgetDashboard,
      navigationBar: {
        name: "Widgets",
      },
    },
    {
      path: "/widget/:id",
      name: "Custom Widget Details",
      requiredModules: [modules.ORGANIZATION_MANAGER],
      component: CustomWidgetDetails,
    },

    // Reports
    {
      path: "/create_report",
      name: "Create a Report",
      component: CreateReport,
    },
    {
      path: "/list",
      name: "Reports",
      component: ReportDashboard,
      navigationBar: {
        name: "Saved Reports",
      },
    },
    {
      path: "/list/:id",
      name: "Report Details",
      component: ReportDetails,
    },
  ],
};
