import { Alert } from "@mui/material";

import type { BlackKiteResponse } from "../components/VendorBlackKiteCompany";

/**
 * @description Black Kite Grade component
 */
const BlackKiteGrade = ({ GradeLetter }: BlackKiteResponse): JSX.Element => {
  return (
    <div>
      {(() => {
        if (GradeLetter?.includes("A") || GradeLetter?.includes("B")) {
          return (
            <Alert severity="success">
              GRADE: <strong>{GradeLetter}</strong>
            </Alert>
          );
        } else if (GradeLetter?.includes("C") || GradeLetter?.includes("D")) {
          return (
            <Alert severity="warning">
              GRADE: <strong>{GradeLetter}</strong>
            </Alert>
          );
        } else if (GradeLetter?.includes("E") || GradeLetter?.includes("F")) {
          return (
            <Alert severity="error">
              GRADE: <strong>{GradeLetter}</strong>
            </Alert>
          );
        } else {
          return (
            <Alert severity="info">
              GRADE: <strong>{GradeLetter}</strong>
            </Alert>
          );
        }
      })()}
    </div>
  );
};

export default BlackKiteGrade;
