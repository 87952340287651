import type { ReactElement } from "react";

import IconError from "@assets/svg/functional/icon-error.svg";
import IconInfo from "@assets/svg/functional/icon-info.svg";
import IconSuccess from "@assets/svg/functional/icon-success.svg";
import IconWarning from "@assets/svg/functional/icon-warning.svg";

import type { TypeOptions } from "../index";

export const ToastIcon = ({ type }: { type: TypeOptions }): ReactElement => {
  switch (type) {
    case "success":
      return <IconSuccess />;
    case "error":
      return <IconError />;
    case "warning":
      return <IconWarning />;
    case "info":
      return <IconInfo />;
    case "default":
    default:
      return <IconSuccess />;
  }
};
