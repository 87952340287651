import type { ReactElement } from "react";

import Skeleton from "@mui/material/Skeleton";

import type { SkeletonProps } from "@mui/material/Skeleton";

export interface HandleLoaderProps extends Pick<SkeletonProps, "width" | "height" | "variant" | "animation"> {
  component: ReactElement;
  isLoading: boolean;
}

/**
 * Utility function for displaying a loader component while waiting on a condition
 */
export const handleLoader = ({
  component,
  isLoading,
  width,
  height,
  variant,
  animation,
}: HandleLoaderProps): JSX.Element => {
  if (isLoading) {
    return (
      <Skeleton variant={variant} width={width} height={height} animation={animation ?? "wave"}>
        {component}
      </Skeleton>
    );
  }

  return component;
};
