import type { ComponentPropsWithRef, PropsWithChildren, ReactElement } from "react";

import cx from "classnames";
import { forwardRef } from "react";

import styles from "./StylelessButton.module.scss";

export interface StylelessButtonProps extends PropsWithChildren, ComponentPropsWithRef<"button"> {}

/**
 * A button component is an interactive UI element used to trigger actions or navigate within an application,
 * providing a clear and intuitive way for users to interact with the system.
 **/
const StylelessButton = forwardRef<HTMLButtonElement, StylelessButtonProps>(
  ({ children, className, ...props }, ref): ReactElement => {
    const classNames = cx(styles["styleless-button"], className);

    return (
      <button ref={ref} className={classNames} aria-disabled={props.disabled} {...props}>
        {children}
      </button>
    );
  },
);

StylelessButton.displayName = "StylelessButton";
export default StylelessButton;
