import React from "react";

import { NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

import type { NavigationModule } from "@application/Navigation/types";

const ResponseDashboard = React.lazy(() => import("@views/Response/Dashboard/ResponseDashboard"));
const IncidentGrid = React.lazy(() => import("@views/Response/Incidents/components/IncidentGrid"));
const IncidentDetails = React.lazy(() => import("@views/Response/Incidents/components/IncidentDetails"));
const PlaybookDetails = React.lazy(() => import("@views/Response/Plan/Playbooks/components/PlaybookDetails"));
const ExerciseGrid = React.lazy(() => import("@views/Response/Exercises/components/ExerciseGrid"));
const ExerciseDetails = React.lazy(() => import("@views/Response/Exercises/components/ExerciseDetails"));
const ResponseTeamGrid = React.lazy(() => import("@views/Response/ResponseTeams/components/ResponseTeamGrid"));
const ResponseTeamDetails = React.lazy(() => import("@views/Response/ResponseTeams/components/ResponseTeamDetails"));
const IncidentResponsePlan = React.lazy(
  () => import("@views/Response/IncidentResponsePlan/components/IncidentResponsePlan"),
);

export const incidentResponseNavigation: NavigationModule = {
  moduleName: modules.INCIDENT_RESPONSE,
  sectionName: NavigationSectionName.SERVICES,
  name: "Response",
  path: "/response",
  defaultRoutePath: "/dashboard",
  icon: IconName.RESPONSE,
  routes: [
    {
      path: "/dashboard",
      name: "Incident Response Dashboard",
      component: ResponseDashboard,
      navigationBar: {
        name: "Dashboard",
      },
    },
    {
      path: "/plan",
      name: "Incident Response Plan",
      component: IncidentResponsePlan,
      navigationBar: {
        name: "Plan",
      },
    },
    {
      path: "/exercises",
      name: "Exercises",
      component: ExerciseGrid,
    },
    {
      path: "/exercises/:id",
      name: "Exercise Details",
      component: ExerciseDetails,
    },
    {
      path: "/incidents",
      name: "Incidents",
      component: IncidentGrid,
    },
    {
      path: "/incidents/:id",
      name: "Incident Details",
      component: IncidentDetails,
    },
    {
      path: "/playbooks/:id",
      name: "Playbook Details",
      component: PlaybookDetails,
    },
    {
      path: "/teams",
      name: "Response Teams",
      component: ResponseTeamGrid,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/teams/:id",
      name: "Response Team Details",
      component: ResponseTeamDetails,
    },
  ],
};
