import { TOOL_TYPES } from "@rivial-security/integration-utils";

import StyledWrapper from "@utils/GenericComponents/StyledWrapper";

import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import CheckTool from "../../../Tool/components/CheckTool";
import { useKnowBe4TrainingDataGrid } from "../hooks/useKnowBe4TrainingDataGrid";

/**
 * @description Display list of KnowBe4 Training Campaigns component
 * @param {object} props - The props passed to the component
 */
const KnowBe4TrainingDataGrid = (props) => {
  const toolType = TOOL_TYPES.KNOW_BE_4;
  return useKnowBe4TrainingDataGrid({ toolType, ...props }).dashboardDisplay;
};

const KnowBe4TrainingDataGridWithCheck = (props) => {
  const toolType = TOOL_TYPES.KNOW_BE_4;

  return (
    <CheckTool toolType={toolType} organizationID={props?.organizationID}>
      <StyledWrapper wrapperStyle={{ height: "30em" }}>
        <KnowBe4TrainingDataGrid {...props} />
      </StyledWrapper>
    </CheckTool>
  );
};

export default withOrganizationCheck(KnowBe4TrainingDataGridWithCheck);
