import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useGUID } from "../../../../../../../hooks/functional/useGUID";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { GENERIC_FIELD_TYPES } from "../../../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * Displays ImplementationRating field for Risk Control Details
 * @param {object} item - risk control data
 * @param {function} resetFunction - used to re-query item after update
 * @param {boolean} [disableTooltip = false] - if true will hide the help tool tip
 * @param {function} [updateItemById] - present if in a grid
 * @param {object} ...props - any other props to pass to GenericEditFieldV3
 * @returns {JSX.Element}
 * @constructor
 */
const ImplementationRating = ({ item, resetFunction, disableTooltip = false, updateItemById, ...props }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL;
  const { updateMutation: updateRiskControl } = generateGraphql("RiskControl", ["implementationRating"]);

  const [guid] = useGUID();

  return (
    <span id={guid}>
      <GenericEditFieldV3
        item={item}
        field="implementationRating"
        mutation={updateRiskControl}
        module={module}
        resource={resource}
        inputType={GENERIC_FIELD_TYPES.PERCENTAGE}
        resetFunction={resetFunction}
        updateItemById={updateItemById}
        allowEmpty={false}
        tooltip={
          !disableTooltip
            ? "Percentage representing how well this control is implemented across the whole organization."
            : undefined
        }
        {...props}
      />
    </span>
  );
};

export default withOrganizationCheck(ImplementationRating);
