import type { ReactElement } from "react";

import AutoAwesome from "@mui/icons-material/AutoAwesome";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { usePolicyGenerationAvailable } from "../../../AI/AIPolicyGeneration/hooks/usePolicyGenerationAvailable";

/**
 * Displays a button to navigate to the AI Policy Generation page.
 * Checks if the user has the correct permissions and if the organization has AI enabled.
 */
const AIPolicyGenerationButton = ({ organizationID }: { organizationID: string }): ReactElement => {
  const { isAiEnabled, isVisible } = usePolicyGenerationAvailable({
    organizationID,
  });

  return (
    <Button
      sx={{
        display: isVisible ? undefined : "none",
      }}
      variant="outlined"
      startIcon={<AutoAwesome />}
      component={Link}
      to="/governance/policies/ai_policy_generation"
      color={"primary"}
      disabled={!isAiEnabled}
    >
      AI Policy Generation
    </Button>
  );
};

export default AIPolicyGenerationButton;
