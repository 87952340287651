import { useCustomDate } from "../hooks/useCustomDate";

import type { InputConfig } from "../hooks/useCustomDate";

interface CustomDateProps {
  field: string;
  value: string | Date | null;
  setValue: (value: string | Date | null) => void;
  inputConfig: InputConfig;
}

/**
 * A component instance of the useCustomDate.js hook
 * @param {string} field - the name of the field being displayed (should be present as a property in item)
 * @param {string | Date | null} value - the current or default value for the field
 * @param {function} setValue - callback for when data has changed (pass in the current sate of the input)
 * @param {object[]} inputConfig - holds settings for the date component
 */
const CustomDate = ({ field, value, setValue, inputConfig }: CustomDateProps): React.ReactNode => {
  return useCustomDate({ field, value, setValue, inputConfig }).display;
};

export default CustomDate;
