import { eventLogger } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { GetQuery } from "@utils/Functions/Graphql/GetQuery";

import type { CustomResourceType } from "schema-types";

export const getQuestionnaireResponses = async ({
  questionnaireID,
}: {
  questionnaireID: string;
}): Promise<CustomResourceType[]> => {
  if (!questionnaireID) {
    return [];
  }
  const { getQuery } = generateGraphql("Questionnaire", ["customResourceType"], {
    customResourceType: `{ id customResourceEntries { items { id customResourceType { id name } customResourceTypeID customFieldData } } }`,
  });

  try {
    const customResourceType = await GetQuery({
      query: getQuery,
      variables: { id: questionnaireID },
    });
    return customResourceType?.customResourceType?.customResourceEntries?.items ?? [];
  } catch (error) {
    eventLogger("Error getting questionnaire responses", error);
    return [];
  }
};
