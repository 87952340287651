import { gql } from "@graphql/types";

export const DOWNLOAD_ARTIFACTS = gql(`
  query complianceDownloadArtifacts($jobId: ID!, $groupBy: ArtifactGroup!, $filterBy: ArtifactFilterBy!, $dateRange: DateRange!, $archiveFileName: String!) {
    complianceDownloadArtifacts(jobId: $jobId, groupBy: $groupBy, filterBy: $filterBy, dateRange: $dateRange, archiveFileName: $archiveFileName) {
      archiveUrl
      ownerGroup
      message
    }
  }
`);
