import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { Button } from "reactstrap";

import { useDetailsBar } from "../../../application/Layout/components/LayoutSidebar/hooks/useDetailsBar";
import { helpCenterUrl } from "../../../env-config";

import type { UseHelpCenterReturn } from "./types/useHelpCenter";

const useStyles = makeStyles(() => ({
  stretch: { height: "100%", width: "100%" },
  item: { display: "flex", flexDirection: "column" },
}));

/**
 * Holds the logic for the Help Center *
 * TODO: Implement route-specific Logic and Hyperlink logic
 */
export const useHelpCenter = ({ isMobile }: { isMobile: boolean }): UseHelpCenterReturn => {
  const classes = useStyles();

  /**
   * Details bar hook just for handling the open/close and window size state logic
   */
  const detailsBar = useDetailsBar();

  /**
   * Determines the help center drawer size.
   * Uses relative column units but converts to viewport units
   * @param {number} columnSize
   */
  const getWidth = (columnSize: number): string => {
    if (detailsBar.fullWindow) {
      return "100%";
    } else {
      switch (columnSize) {
        case 4:
          return "33vw";
        case 6:
          return "50vw";
        case 8:
          return "66vw";
        default:
          return "33vw";
      }
    }
  };

  /**
   * Current src url of the iFrame
   */
  const [helpCenterLink, setHelpCenterLink] = useState(helpCenterUrl);

  /**
   * Onclose, reset the help center link and close the details bar
   */
  const handleCloseClick = (): void => {
    setHelpCenterLink(helpCenterUrl);
    detailsBar.setIsOpen(false);
  };

  const drawer = (
    <Drawer
      variant="persistent"
      open={detailsBar.isOpen}
      anchor={"right"}
      PaperProps={{
        style: { width: getWidth(detailsBar.columnSize), height: "100%" },
      }}
    >
      <div style={{ height: "100%" }}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
          <Grid item className={classes.item}>
            <span
              className={"e-card"}
              style={{
                borderRadius: "0em",
                width: "100%",
                opacity: 0.9,
                height: "2em",
              }}
            >
              <Grid direction="row" justifyContent="space-between" container style={{ paddingLeft: "1em" }}>
                <Grid item>{PLATFORM_VERSION ?? ""}</Grid>
                <Grid item>
                  {!isMobile && detailsBar.sizeButtons}
                  <Button
                    size="sm"
                    style={{ margin: "0.4em" }}
                    color={"ghost-danger"}
                    className={"float-right"}
                    onClick={handleCloseClick}
                  >
                    <i className={"icon-close"} />
                  </Button>
                </Grid>
              </Grid>
            </span>
          </Grid>
          <Grid item className={classes.item}>
            {/*
                Note: was having issues getting the height to fit at 100% without scrollbars.
            */}
            <span className={classes.stretch} style={{ height: "95vh" }}>
              <iframe
                title="Help Center"
                sandbox="allow-same-origin allow-scripts"
                referrerPolicy="no-referrer"
                style={{ minWidth: "100%", height: "100%", border: 0 }}
                src={helpCenterLink}
              />
            </span>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );

  return {
    ...detailsBar,
    helpCenterLink,
    setHelpCenterLink,
    drawer,
  };
};
