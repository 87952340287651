import type { ReactElement } from "react";

import IconColumn from "../../../../assets/svg/file/icon-column.svg";
import IconConvert from "../../../../assets/svg/file/icon-convert.svg";
import IconDownload from "../../../../assets/svg/file/icon-download.svg";
import IconDuplicate from "../../../../assets/svg/file/icon-duplicate.svg";
import IconFile from "../../../../assets/svg/file/icon-file-file.svg";
import IconFileJpg from "../../../../assets/svg/file/icon-file-jpg.svg";
import IconFileMp4 from "../../../../assets/svg/file/icon-file-mp4.svg";
import IconFilePdf from "../../../../assets/svg/file/icon-file-pdf.svg";
import IconFilePng from "../../../../assets/svg/file/icon-file-png.svg";
import IconFileStl from "../../../../assets/svg/file/icon-file-stl.svg";
import IconFileSvg from "../../../../assets/svg/file/icon-file-svg.svg";
import IconFilter from "../../../../assets/svg/file/icon-filter.svg";
import IconFolder from "../../../../assets/svg/file/icon-folder.svg";
import IconNote from "../../../../assets/svg/file/icon-note.svg";
import IconPeople from "../../../../assets/svg/file/icon-people.svg";
import IconPreview from "../../../../assets/svg/file/icon-preview.svg";
import IconRefresh from "../../../../assets/svg/file/icon-refresh.svg";
import IconUpload from "../../../../assets/svg/file/icon-upload.svg";
import IconAdd from "../../../../assets/svg/functional/icon-add.svg";
import IconClose from "../../../../assets/svg/functional/icon-close.svg";
import IconDelete from "../../../../assets/svg/functional/icon-delete.svg";
import IconEdit from "../../../../assets/svg/functional/icon-edit.svg";
import IconError from "../../../../assets/svg/functional/icon-error.svg";
import IconHelp from "../../../../assets/svg/functional/icon-help.svg";
import IconInfo from "../../../../assets/svg/functional/icon-info.svg";
import IconLogout from "../../../../assets/svg/functional/icon-logout.svg";
import IconMore from "../../../../assets/svg/functional/icon-more.svg";
import IconNotificationActive from "../../../../assets/svg/functional/icon-notification-active.svg";
import IconNotificationDefault from "../../../../assets/svg/functional/icon-notification-default.svg";
import IconPlaceholder from "../../../../assets/svg/functional/icon-placeholder.svg";
import IconSave from "../../../../assets/svg/functional/icon-save.svg";
import IconSearch from "../../../../assets/svg/functional/icon-search.svg";
import IconSuccess from "../../../../assets/svg/functional/icon-success.svg";
import IconWarning from "../../../../assets/svg/functional/icon-warning.svg";
import IconDropper from "../../../../assets/svg/input/icon-color-dropper.svg";
import IconDate from "../../../../assets/svg/input/icon-date.svg";
import IconInputStepperActive from "../../../../assets/svg/input/icon-input-stepper-active.svg";
import IconInputStepperDefault from "../../../../assets/svg/input/icon-input-stepper-default.svg";
import IconTime from "../../../../assets/svg/input/icon-time.svg";
import IconMicrosoft from "../../../../assets/svg/logos/logo-microsoft.svg";
import IconOkta from "../../../../assets/svg/logos/logo-okta.svg";
import IconRivialCollapsed from "../../../../assets/svg/logos/logo-rivial-collapsed.svg";
import IconRivialExpanded from "../../../../assets/svg/logos/logo-rivial-expanded.svg";
import IconAction from "../../../../assets/svg/navigation/icon-action.svg";
import IconAdmin from "../../../../assets/svg/navigation/icon-admin.svg";
import IconCode from "../../../../assets/svg/navigation/icon-code.svg";
import IconCompliance from "../../../../assets/svg/navigation/icon-compliance.svg";
import IconIntegration from "../../../../assets/svg/navigation/icon-integration.svg";
import IconMetrics from "../../../../assets/svg/navigation/icon-metrics.svg";
import IconOrganization from "../../../../assets/svg/navigation/icon-organization.svg";
import IconProgram from "../../../../assets/svg/navigation/icon-program.svg";
import IconResponse from "../../../../assets/svg/navigation/icon-response.svg";
import IconRisk from "../../../../assets/svg/navigation/icon-risk.svg";
import IconTesting from "../../../../assets/svg/navigation/icon-testing.svg";
import IconTool from "../../../../assets/svg/navigation/icon-tool.svg";
import IconTraining from "../../../../assets/svg/navigation/icon-training.svg";
import IconVendor from "../../../../assets/svg/navigation/icon-vendor.svg";
import IconNoData from "../../../../assets/svg/others/icon-no-data.svg";
import IconCheckboxSelectedDefault from "../../../../assets/svg/toggle/icon-checkbox-selected-default.svg";
import IconCheckboxSelectedDisabled from "../../../../assets/svg/toggle/icon-checkbox-selected-disabled.svg";
import IconCheckboxUnselectedDefault from "../../../../assets/svg/toggle/icon-checkbox-unselected-default.svg";
import IconCheckboxUnselectedDisabled from "../../../../assets/svg/toggle/icon-checkbox-unselected-disabled.svg";
import IconCollapse from "../../../../assets/svg/toggle/icon-collapse.svg";
import IconExpand from "../../../../assets/svg/toggle/icon-expand.svg";
import IconFavoriteOff from "../../../../assets/svg/toggle/icon-favorite-off.svg";
import IconFavoriteOn from "../../../../assets/svg/toggle/icon-favorite-on.svg";
import IconPin from "../../../../assets/svg/toggle/icon-pin.svg";
import IconRadioButtonSelectedDefault from "../../../../assets/svg/toggle/icon-radio-button-selected-default.svg";
import IconRadioButtonSelectedDisabled from "../../../../assets/svg/toggle/icon-radio-button-selected-disabled.svg";
import IconRadioButtonUnselectedDefault from "../../../../assets/svg/toggle/icon-radio-button-unselected-default.svg";
import IconRadioButtonUnselectedDisabled from "../../../../assets/svg/toggle/icon-radio-button-unselected-disabled.svg";
import IconUnpin from "../../../../assets/svg/toggle/icon-unpin.svg";
import IconVisibilityOff from "../../../../assets/svg/toggle/icon-visibility-off.svg";
import IconVisibilityOn from "../../../../assets/svg/toggle/icon-visibility-on.svg";
import styles from "../Icons.module.scss";

import { IconArrowDown, IconArrowLeft, IconArrowRight, IconArrowUp } from "./ArrowIcons";
import { IconChevronDown, IconChevronLeft, IconChevronRight, IconChevronUp } from "./ChevronIcons";

const navigationCategory = "Navigation";
const functionalCategory = "Functional";
const fileCategory = "File";
const toggleCategory = "Toggle";
const inputCategory = "Input";
const othersCategory = "Others";
const logosCategory = "Logo";
export enum IconName {
  ACTION = "Action",
  ADD = "Add",
  ADMIN = "Admin",
  ARROW_DOWN = "Arrow Down",
  ARROW_LEFT = "Arrow Left",
  ARROW_RIGHT = "Arrow Right",
  ARROW_UP = "Arrow Up",
  CHECKBOX_DISABLED = "Checkbox Disabled",
  CHECKBOX_SELECTED_DEFAULT = "Checkbox Selected Default",
  CHECKBOX_UNSELECTED_DEFAULT = "Checkbox Unselected Default",
  CHECKBOX_UNSELECTED_DISABLED = "Checkbox Unselected Disabled",
  CHEVRON_DOWN = "Chevron Down",
  CHEVRON_LEFT = "Chevron Left",
  CHEVRON_RIGHT = "Chevron Right",
  CHEVRON_UP = "Chevron Up",
  CLOSE = "Close",
  CODE = "Code",
  COLLAPSE = "Collapse",
  COLOR_DROPPER = "Color Dropper",
  COLUMN = "Column",
  COMPLIANCE = "Compliance",
  CONVERT = "Convert",
  DATE = "Date",
  DELETE = "Delete",
  DOWNLOAD = "Download",
  DUPLICATE = "Duplicate",
  EDIT = "Edit",
  ERROR = "Error",
  EXPAND = "Expand",
  FAVORITE_OFF = "Favorite Off",
  FAVORITE_ON = "Favorite On",
  FILE = "File",
  FILE_JPG = "File (JPG)",
  FILE_MP4 = "File (MP4)",
  FILE_PDF = "File (PDF)",
  FILE_PNG = "File (PNG)",
  FILE_STL = "File (STL)",
  FILE_SVG = "File (SVG)",
  FILTER = "Filter",
  FOLDER = "Folder",
  HELP = "Help",
  INFO = "Info",
  INPUT_STEPPER_ACTIVE_DECREMENT = "Input Stepper Active Decrement",
  INPUT_STEPPER_ACTIVE_INCREMENT = "Input Stepper Active Increment",
  INPUT_STEPPER_DEFAULT_DECREMENT = "Input Stepper Default Decrement",
  INPUT_STEPPER_DEFAULT_INCREMENT = "Input Stepper Default Increment",
  INTEGRATION = "Integration",
  LOGOUT = "Logout",
  METRICS = "Metrics",
  MICROSOFT = "Microsoft",
  MORE = "More",
  NO_DATA = "No Data",
  NOTE = "Note",
  NOTIFICATION_ACTIVE = "Notification Active",
  NOTIFICATION_DEFAULT = "Notification Default",
  OKTA = "Okta",
  ORGANIZATION = "Organization",
  PEOPLE = "People",
  PIN = "Pin",
  PLACEHOLDER = "Placeholder",
  PREVIEW = "Preview",
  PROGRAM = "Program",
  RADIO_BUTTON_DISABLED = "Radio Button Disabled",
  RADIO_BUTTON_SELECTED_DEFAULT = "Radio Button Selected Default",
  RADIO_BUTTON_UNSELECTED_DEFAULT = "Radio Button Unselected Default",
  RADIO_BUTTON_UNSELECTED_DISABLED = "Radio Button Unselected Disabled",
  REFRESH = "Refresh",
  RESPONSE = "Response",
  RISK = "Risk",
  RIVIAL_COLLAPSED = "Rivial Collapsed",
  RIVIAL_EXPANDED = "Rivial Expanded",
  SAVE = "Save",
  SEARCH = "Search",
  SUCCESS = "Success",
  TESTING = "Testing",
  TIME = "Time",
  TOOL = "Tool",
  TRAINING = "Training",
  UNPIN = "Unpin",
  UPLOAD = "Upload",
  VENDOR = "Vendor",
  VISIBILITY_OFF = "Visibility Off",
  VISIBILITY_ON = "Visibility On",
  WARNING = "Warning",
}

export interface IconParams {
  name: IconName;
  designToken: string;
  category: string;
  icon: ReactElement;
}

export const navigationIcons: IconParams[] = [
  {
    name: IconName.PROGRAM,
    designToken: "icon-program",
    category: navigationCategory,
    icon: <IconProgram />,
  },
  {
    name: IconName.RISK,
    designToken: "icon-risk",
    category: navigationCategory,
    icon: <IconRisk />,
  },
  {
    name: IconName.COMPLIANCE,
    designToken: "icon-compliance",
    category: navigationCategory,
    icon: <IconCompliance />,
  },
  {
    name: IconName.TESTING,
    designToken: "icon-testing",
    category: navigationCategory,
    icon: <IconTesting />,
  },
  {
    name: IconName.VENDOR,
    designToken: "icon-vendor",
    category: navigationCategory,
    icon: <IconVendor />,
  },
  {
    name: IconName.RESPONSE,
    designToken: "icon-response",
    category: navigationCategory,
    icon: <IconResponse />,
  },
  {
    name: IconName.TRAINING,
    designToken: "icon-training",
    category: navigationCategory,
    icon: <IconTraining />,
  },
  {
    name: IconName.ACTION,
    designToken: "icon-action",
    category: navigationCategory,
    icon: <IconAction />,
  },
  {
    name: IconName.TOOL,
    designToken: "icon-tool",
    category: navigationCategory,
    icon: <IconTool />,
  },
  {
    name: IconName.INTEGRATION,
    designToken: "icon-integration",
    category: navigationCategory,
    icon: <IconIntegration />,
  },
  {
    name: IconName.METRICS,
    designToken: "icon-metrics",
    category: navigationCategory,
    icon: <IconMetrics />,
  },
  {
    name: IconName.ORGANIZATION,
    designToken: "icon-organization",
    category: navigationCategory,
    icon: <IconOrganization />,
  },
  {
    name: IconName.ADMIN,
    designToken: "icon-admin",
    category: navigationCategory,
    icon: <IconAdmin />,
  },
  {
    name: IconName.CODE,
    designToken: "icon-code",
    category: navigationCategory,
    icon: <IconCode />,
  },
];

const functionalIcons: IconParams[] = [
  {
    name: IconName.PLACEHOLDER,
    designToken: "icon-placeholder",
    category: functionalCategory,
    icon: <IconPlaceholder />,
  },
  {
    name: IconName.ADD,
    designToken: "icon-add",
    category: functionalCategory,
    icon: <IconAdd />,
  },
  {
    name: IconName.EDIT,
    designToken: "icon-edit",
    category: functionalCategory,
    icon: <IconEdit />,
  },
  {
    name: IconName.SAVE,
    designToken: "icon-save",
    category: functionalCategory,
    icon: <IconSave />,
  },
  {
    name: IconName.DELETE,
    designToken: "icon-delete",
    category: functionalCategory,
    icon: <IconDelete />,
  },
  {
    name: IconName.SEARCH,
    designToken: "icon-search",
    category: functionalCategory,
    icon: <IconSearch />,
  },
  {
    name: IconName.SUCCESS,
    designToken: "icon-success",
    category: functionalCategory,
    icon: <IconSuccess />,
  },
  {
    name: IconName.INFO,
    designToken: "icon-info",
    category: functionalCategory,
    icon: <IconInfo />,
  },
  {
    name: IconName.WARNING,
    designToken: "icon-warning",
    category: functionalCategory,
    icon: <IconWarning />,
  },
  {
    name: IconName.ERROR,
    designToken: "icon-error",
    category: functionalCategory,
    icon: <IconError />,
  },
  {
    name: IconName.HELP,
    designToken: "icon-help",
    category: functionalCategory,
    icon: <IconHelp />,
  },
  {
    name: IconName.CLOSE,
    designToken: "icon-close",
    category: functionalCategory,
    icon: <IconClose />,
  },
  {
    name: IconName.MORE,
    designToken: "icon-more",
    category: functionalCategory,
    icon: <IconMore />,
  },
  {
    name: IconName.LOGOUT,
    designToken: "icon-logout",
    category: functionalCategory,
    icon: <IconLogout />,
  },
  {
    name: IconName.NOTIFICATION_ACTIVE,
    designToken: "icon-notification-active",
    category: functionalCategory,
    icon: <IconNotificationActive />,
  },
  {
    name: IconName.NOTIFICATION_DEFAULT,
    designToken: "icon-notification-default",
    category: functionalCategory,
    icon: <IconNotificationDefault />,
  },
];

const fileIcons: IconParams[] = [
  {
    name: IconName.FOLDER,
    designToken: "icon-folder",
    category: fileCategory,
    icon: <IconFolder />,
  },
  {
    name: IconName.FILE,
    designToken: "icon-file",
    category: fileCategory,
    icon: <IconFile />,
  },
  {
    name: IconName.FILE_PDF,
    designToken: "icon-file-pdf",
    category: fileCategory,
    icon: <IconFilePdf />,
  },
  {
    name: IconName.FILE_JPG,
    designToken: "icon-file-jpg",
    category: fileCategory,
    icon: <IconFileJpg />,
  },
  {
    name: IconName.FILE_PNG,
    designToken: "icon-file-png",
    category: fileCategory,
    icon: <IconFilePng />,
  },
  {
    name: IconName.FILE_SVG,
    designToken: "icon-file-svg",
    category: fileCategory,
    icon: <IconFileSvg />,
  },
  {
    name: IconName.FILE_MP4,
    designToken: "icon-file-mp4",
    category: fileCategory,
    icon: <IconFileMp4 />,
  },
  {
    name: IconName.FILE_STL,
    designToken: "icon-file-stl",
    category: fileCategory,
    icon: <IconFileStl />,
  },
  {
    name: IconName.PREVIEW,
    designToken: "icon-preview",
    category: fileCategory,
    icon: <IconPreview />,
  },
  {
    name: IconName.CONVERT,
    designToken: "icon-convert",
    category: fileCategory,
    icon: <IconConvert />,
  },
  {
    name: IconName.UPLOAD,
    designToken: "icon-upload",
    category: fileCategory,
    icon: <IconUpload />,
  },
  {
    name: IconName.DOWNLOAD,
    designToken: "icon-download",
    category: fileCategory,
    icon: <IconDownload />,
  },
  {
    name: IconName.FILTER,
    designToken: "icon-filter",
    category: fileCategory,
    icon: <IconFilter />,
  },
  {
    name: IconName.REFRESH,
    designToken: "icon-refresh",
    category: fileCategory,
    icon: <IconRefresh />,
  },
  {
    name: IconName.COLUMN,
    designToken: "icon-column",
    category: fileCategory,
    icon: <IconColumn />,
  },
  {
    name: IconName.NOTE,
    designToken: "icon-note",
    category: fileCategory,
    icon: <IconNote />,
  },
  {
    name: IconName.PEOPLE,
    designToken: "icon-people",
    category: fileCategory,
    icon: <IconPeople />,
  },
  {
    name: IconName.DUPLICATE,
    designToken: "icon-duplicate",
    category: fileCategory,
    icon: <IconDuplicate />,
  },
];

const toggleIcons: IconParams[] = [
  {
    name: IconName.RADIO_BUTTON_UNSELECTED_DEFAULT,
    designToken: "icon-radio-button-unselected-default",
    category: toggleCategory,
    icon: <IconRadioButtonUnselectedDefault />,
  },
  {
    name: IconName.RADIO_BUTTON_UNSELECTED_DISABLED,
    designToken: "icon-radio-button-unselected-disabled",
    category: toggleCategory,
    icon: <IconRadioButtonUnselectedDisabled />,
  },
  {
    name: IconName.RADIO_BUTTON_SELECTED_DEFAULT,
    designToken: "icon-radio-button-selected-default",
    category: toggleCategory,
    icon: <IconRadioButtonSelectedDefault />,
  },
  {
    name: IconName.RADIO_BUTTON_DISABLED,
    designToken: "icon-radio-button-disabled",
    category: toggleCategory,
    icon: <IconRadioButtonSelectedDisabled />,
  },
  {
    name: IconName.CHECKBOX_UNSELECTED_DEFAULT,
    designToken: "icon-checkbox-unselected-default",
    category: toggleCategory,
    icon: <IconCheckboxUnselectedDefault />,
  },
  {
    name: IconName.CHECKBOX_UNSELECTED_DISABLED,
    designToken: "icon-checkbox-unselected-disabled",
    category: toggleCategory,
    icon: <IconCheckboxUnselectedDisabled />,
  },
  {
    name: IconName.CHECKBOX_SELECTED_DEFAULT,
    designToken: "icon-checkbox-selected-default",
    category: toggleCategory,
    icon: <IconCheckboxSelectedDefault />,
  },
  {
    name: IconName.CHECKBOX_DISABLED,
    designToken: "icon-checkbox-disabled",
    category: toggleCategory,
    icon: <IconCheckboxSelectedDisabled />,
  },
  {
    name: IconName.FAVORITE_ON,
    designToken: "icon-favorite-on",
    category: toggleCategory,
    icon: <IconFavoriteOn />,
  },
  {
    name: IconName.FAVORITE_OFF,
    designToken: "icon-favorite-off",
    category: toggleCategory,
    icon: <IconFavoriteOff />,
  },
  {
    name: IconName.VISIBILITY_ON,
    designToken: "icon-visibility-on",
    category: toggleCategory,
    icon: <IconVisibilityOn />,
  },
  {
    name: IconName.VISIBILITY_OFF,
    designToken: "icon-visibility-off",
    category: toggleCategory,
    icon: <IconVisibilityOff />,
  },
  {
    name: IconName.EXPAND,
    designToken: "icon-expand",
    category: toggleCategory,
    icon: <IconExpand />,
  },
  {
    name: IconName.COLLAPSE,
    designToken: "icon-collapse",
    category: toggleCategory,
    icon: <IconCollapse />,
  },
  {
    name: IconName.PIN,
    designToken: "icon-pin",
    category: toggleCategory,
    icon: <IconPin />,
  },
  {
    name: IconName.UNPIN,
    designToken: "icon-unpin",
    category: toggleCategory,
    icon: <IconUnpin />,
  },
];

const inputIcons: IconParams[] = [
  {
    name: IconName.CHEVRON_UP,
    designToken: "icon-chevron-up",
    category: inputCategory,
    icon: <IconChevronUp />,
  },
  {
    name: IconName.CHEVRON_RIGHT,
    designToken: "icon-chevron-right",
    category: inputCategory,
    icon: <IconChevronRight />,
  },
  {
    name: IconName.CHEVRON_DOWN,
    designToken: "icon-chevron-down",
    category: inputCategory,
    icon: <IconChevronDown />,
  },
  {
    name: IconName.CHEVRON_LEFT,
    designToken: "icon-chevron-left",
    category: inputCategory,
    icon: <IconChevronLeft />,
  },
  {
    name: IconName.DATE,
    designToken: "icon-date",
    category: inputCategory,
    icon: <IconDate />,
  },
  {
    name: IconName.TIME,
    designToken: "icon-time",
    category: inputCategory,
    icon: <IconTime />,
  },
  {
    name: IconName.INPUT_STEPPER_DEFAULT_INCREMENT,
    designToken: "icon-input-stepper-default-increment",
    category: inputCategory,
    icon: <IconInputStepperDefault />,
  },
  {
    name: IconName.INPUT_STEPPER_DEFAULT_DECREMENT,
    designToken: "icon-input-stepper-default-decrement",
    category: inputCategory,
    icon: <IconInputStepperDefault className={styles.down} />,
  },
  {
    name: IconName.INPUT_STEPPER_ACTIVE_INCREMENT,
    designToken: "icon-input-stepper-active-increment",
    category: inputCategory,
    icon: <IconInputStepperActive />,
  },
  {
    name: IconName.INPUT_STEPPER_ACTIVE_DECREMENT,
    designToken: "icon-input-stepper-active-decrement",
    category: inputCategory,
    icon: <IconInputStepperActive className={styles.down} />,
  },
  {
    name: IconName.COLOR_DROPPER,
    designToken: "icon-color-dropper",
    category: inputCategory,
    icon: <IconDropper />,
  },
];

const othersIcon: IconParams[] = [
  {
    name: IconName.ARROW_UP,
    designToken: "icon-arrow-up",
    category: othersCategory,
    icon: <IconArrowUp />,
  },
  {
    name: IconName.ARROW_RIGHT,
    designToken: "icon-arrow-right",
    category: othersCategory,
    icon: <IconArrowRight />,
  },
  {
    name: IconName.ARROW_DOWN,
    designToken: "icon-arrow-down",
    category: othersCategory,
    icon: <IconArrowDown />,
  },
  {
    name: IconName.ARROW_LEFT,
    designToken: "icon-arrow-left",
    category: othersCategory,
    icon: <IconArrowLeft />,
  },
  {
    name: IconName.NO_DATA,
    designToken: "icon-no-data",
    category: othersCategory,
    icon: <IconNoData />,
  },
];

const logosIcons: IconParams[] = [
  {
    name: IconName.RIVIAL_COLLAPSED,
    designToken: "logo-rivial-collapsed",
    category: logosCategory,
    icon: <IconRivialCollapsed />,
  },
  {
    name: IconName.RIVIAL_EXPANDED,
    designToken: "logo-rivial-expanded",
    category: logosCategory,
    icon: <IconRivialExpanded />,
  },
  {
    name: IconName.MICROSOFT,
    designToken: "logo-microsoft",
    category: logosCategory,
    icon: <IconMicrosoft />,
  },
  {
    name: IconName.OKTA,
    designToken: "logo-okta",
    category: logosCategory,
    icon: <IconOkta />,
  },
];

const Icons = [
  ...navigationIcons,
  ...functionalIcons,
  ...fileIcons,
  ...toggleIcons,
  ...inputIcons,
  ...othersIcon,
  ...logosIcons,
];

export const getIcon = (name: IconName): ReactElement | undefined => Icons.find((icon) => icon.name === name)?.icon;

export default Icons;
