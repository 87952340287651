import type { ReactElement } from "react";

import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";

import DashboardCard from "../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { useSync } from "../../functional/useSync";

export interface Step {
  id: string;
  text: string;
  display?: ReactElement;
}

export interface UseStepperProps {
  steps: Step[];
  currentStep?: Step;
  customStepComponents?: boolean;
}

export interface UseStepper {
  display: ReactElement;
  steps: Step[];
  setSteps: (steps: Step[]) => void;
  activeStep: number;
  activeStepId: string;
  setActiveStep: (step: number) => void;
  isOnLastStep: boolean;
  isOnFirstStep: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
  handleReset: () => void;
  stepperAvailable: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: ".5em",
  },
  instructions: {
    marginTop: ".5em",
    marginBottom: ".5em",
  },
}));

/**
 * A component that allows users to navigate through a series of steps
 * @param stepsInput - initial steps to display
 * @param currentStep - the current step that the user is on
 * @param customStepComponents - if true, the steps will be displayed using the components provided in the steps prop
 * @param props - any additional props to pass down to the step components
 */
export const useStepper = ({
  steps: stepsInput = [],
  currentStep,
  customStepComponents = false,
  ...props
}: UseStepperProps): UseStepper => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepId, setActiveStepId] = useState(stepsInput[0]?.id || "");
  const [steps, setSteps] = useSync(stepsInput);

  useEffect(() => {
    if (currentStep) {
      setActiveStep(steps.findIndex((i: Step) => i.id === currentStep.id));
      setActiveStepId(currentStep.id);
    }
  }, [currentStep, steps]);

  useEffect(() => {
    if (activeStep) {
      setActiveStepId(steps[activeStep]?.id || "");
    }
  }, [activeStep]);

  const isStepOptional = (step: number): boolean => {
    const stepConfig = steps[step];
    return stepConfig?.optional === true;
  };

  const handleNext = (): void => {
    const nextStepIndex = activeStep + 1;
    if (Array.isArray(steps) && steps.length < nextStepIndex) return;
    setActiveStep(nextStepIndex);
  };

  const handlePrevious = (): void => {
    const previousStepIndex = activeStep - 1;
    if (Array.isArray(steps) && previousStepIndex < 0) return;
    setActiveStep(previousStepIndex);
  };

  const isOnLastStep = activeStep === steps.length - 1;
  const isOnFirstStep = activeStep === 0;

  const handleReset = (): void => {
    setActiveStep(0);
    setActiveStepId(steps?.[0]?.id || "");
  };

  const display = (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step: Step, index: number) => {
          const stepProps: {
            completed?: boolean;
          } = {};

          const labelProps: {
            optional?: JSX.Element;
          } = {};

          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          return (
            <Step key={step.text} {...stepProps}>
              <StepLabel {...labelProps}>
                {step.text}
                {customStepComponents && (
                  // @ts-expect-error - DashboardCard is not typed
                  <DashboardCard title={step.text} style={{ textAlign: "left" }}>
                    {step?.display && React.cloneElement(step.display, { ...props })}
                  </DashboardCard>
                )}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );

  return {
    display,
    steps,
    setSteps,
    activeStep,
    activeStepId,
    setActiveStep,
    isOnLastStep,
    isOnFirstStep,
    handleNext,
    handlePrevious,
    handleReset,
    stepperAvailable: true, //used for validating if other fields will be present when destructuring
  };
};
