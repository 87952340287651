import React from "react";

import { NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

import type { NavigationModule } from "@application/Navigation/types";

const Tools = React.lazy(() => import("@views/Tools/Tool/components/ToolDataGrid"));
const ToolDetails = React.lazy(() => import("@views/Tools/Tool/components/ToolDetails"));
const ToolDashboard = React.lazy(() => import("@views/Tools/Tool/components/ToolDashboard"));

/**
 * KnowBe4 components
 */
const KnowBe4TrainingCampaigns = React.lazy(
  () => import("@views/Tools/KnowBe4/Training/components/KnowBe4TrainingDataGrid"),
);
const KnowBe4TrainingCampaignDetails = React.lazy(
  () => import("@views/Tools/KnowBe4/Training/components/KnowBe4TrainingDetails"),
);
const KnowBe4PhishingTests = React.lazy(
  () => import("@views/Tools/KnowBe4/Phishing/components/KnowBe4PhishingDataGrid"),
);
const KnowBe4PhishingTestDetails = React.lazy(
  () => import("@views/Tools/KnowBe4/Phishing/components/KnowBe4PhishingDetails"),
);
const KnowBe4Groups = React.lazy(() => import("@views/Tools/KnowBe4/Groups/components/KnowBe4GroupDataGrid"));
const KnowBe4GroupDetails = React.lazy(() => import("@views/Tools/KnowBe4/Groups/components/KnowBe4GroupDetails"));
const KnowBe4Users = React.lazy(() => import("@views/Tools/KnowBe4/Users/components/KnowBe4UserDataGrid"));
const KnowBe4UserDetails = React.lazy(() => import("@views/Tools/KnowBe4/Users/components/KnowBe4UserDetails"));
const KnowBeDashboard = React.lazy(() => import("@views/Tools/KnowBe4/KnowBe4Dashboard"));

/**
 * Mitre Attack components
 */
const MitreAttackDashboard = React.lazy(() => import("@views/Tools/MitreAttack/MitreAttackDashboard"));
const MitreAttackTechniques = React.lazy(
  () => import("@views/Tools/MitreAttack/Techniques/components/MitreAttackTechniquesDataGrid"),
);

export const toolsNavigation: NavigationModule = {
  moduleName: modules.TOOLS,
  sectionName: NavigationSectionName.TOOLS,
  name: "Integrations",
  path: "/integrations",
  defaultRoutePath: "/configuration",
  icon: IconName.INTEGRATION,
  routes: [
    // KnowBe4 routes
    {
      path: "/know_be_4",
      name: "KnowBe4",
      component: KnowBeDashboard,
    },
    {
      path: "/know_be_4/training",
      name: "KnowBe4 Training Campaigns",
      component: KnowBe4TrainingCampaigns,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/know_be_4/training/:id",
      name: "KnowBe4 Training Campaign Details",
      component: KnowBe4TrainingCampaignDetails,
    },
    {
      path: "/know_be_4/phishing",
      name: "KnowBe4 Phishing Tests",
      component: KnowBe4PhishingTests,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/know_be_4/phishing/:id",
      name: "KnowBe4 Phishing Test Details",
      component: KnowBe4PhishingTestDetails,
    },
    {
      path: "/know_be_4/group",
      name: "KnowBe4 Groups",
      component: KnowBe4Groups,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/know_be_4/group/:id",
      name: "KnowBe4 Group Details",
      component: KnowBe4GroupDetails,
    },
    {
      path: "/know_be_4/user",
      name: "KnowBe4 Users Tests",
      component: KnowBe4Users,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/know_be_4/user/:id",
      name: "KnowBe4 User Details",
      component: KnowBe4UserDetails,
    },

    // Mitre Attack routes
    {
      path: "/mitre_attack",
      name: "Mitre Attack",
      component: MitreAttackDashboard,
      navigationBar: {
        name: "Mitre Att&ck",
      },
    },
    {
      path: "/mitre_attack/techniques",
      name: "Mitre Attack Techniques",
      component: MitreAttackTechniques,
      navigationBar: {
        hidden: true,
      },
    },

    // Configuration and Custom Tools
    {
      path: "/configuration",
      name: "Security Tool Integrations",
      component: Tools,
      navigationBar: {
        name: "Configuration",
      },
    },
    {
      path: "/configuration/:id",
      name: "Security Tool Details",
      component: ToolDetails,
    },
    {
      path: "/dashboard/:id",
      name: "Integration Dashboard",
      component: ToolDashboard,
      navigationBar: {
        hidden: true,
      },
    },
  ],
};
