import modules from "./modules";
import resources from "./resources";

const {
  ORGANIZATION_MANAGER,
  ACTION_CENTER,
  COMPLIANCE,
  RISK,
  VULNERABILITIES,
  GOVERNANCE,
  VENDORS,
  REPORTS,
  ADMINISTRATOR,
  ACCOUNT_MANAGER,
  DEVELOPER,
  INCIDENT_RESPONSE,
  TOOLS,
  HELP_CENTER,
  METRICS,
} = modules;

const {
  // ACCOUNT_MANAGER
  ACCOUNT_DASHBOARD,
  PREFERENCES,

  // ADMINISTRATOR
  BLUEPRINT_TEMPLATE,
  CISSP_REVIEW,
  CONTROL_TEMPLATE,
  CONTROL_FRAMEWORK_TEMPLATE,
  EVIDENCE_TEMPLATE,
  OPERATION_TEAM,
  ORGANIZATION,
  ROLE_TEMPLATE,
  SYSTEM_TEMPLATE,
  RISK_CONTROL_CATEGORY_TEMPLATE,
  RISK_CONTROL_TEMPLATE,
  KEY_RISK_INDICATOR_TEMPLATE,
  THREAT_TEMPLATE,
  INFORMATION_ASSET_TEMPLATE,
  CUSTOM_WIDGET_TEMPLATE,
  CUSTOM_QUERY_TEMPLATE,

  // COMPLIANCE
  AUDIT,
  CONTROL,
  CONTROL_FRAMEWORK,
  DOCUMENT,
  ARTIFACT,
  EVIDENCE,
  COMPLIANCE_CONFIG,

  // DEVELOPER
  FEEDBACK,

  // HELP_CENTER
  HELP_ARTICLE,

  // INCIDENT_RESPONSE
  CLASSIFICATION_TIER,
  DETECTION,
  EXERCISE,
  INCIDENT,
  PLAYBOOK,
  RESPONSE_PLAN,
  RESPONSE_TEAM,

  // METRICS
  METRIC,
  METRIC_TYPE,
  KEY_PERFORMANCE_INDICATOR,

  // ORGANIZATION_MANAGER
  AUTOMATION,
  CUSTOM_DASHBOARD,
  CUSTOM_QUERY,
  CUSTOM_WIDGET,
  DEPARTMENT,
  QUESTIONNAIRE,
  QUESTIONNAIRE_ASSIGNEE,
  EMAIL_TEMPLATE,
  JOB,
  LOCATION,
  POINT_OF_CONTACT,
  ROLE,
  USER,
  BRANDING,

  // GOVERNANCE
  ACTION_ITEM,
  TASK,
  BLUEPRINT,
  IMPLEMENTATION_LEVEL,
  MEETING,
  OBSERVATION,
  POLICY,
  PROGRAM_ELEMENT,
  PROGRAM_SECTION,
  RECOMMENDATION,
  SOURCE,

  // REPORTS
  REPORT,

  // RISK
  INFORMATION_ASSET,
  INFORMATION_SYSTEM,
  KEY_RISK_INDICATOR,
  LOSS_TOLERANCE,
  RISK_CHANGE,
  RISK_CONFIG,
  RISK_CONTROL,
  RISK_CONTROL_CATEGORY,
  THREAT,

  // VULNERABILITIES
  ASSESSMENT,
  SECURITY_APPLIANCE,
  TARGET,
  VULNERABILITY,

  // TOOLS
  TOOL,
  KNOW_BE_4_TRAINING,
  KNOW_BE_4_PHISHING,
  KNOW_BE_4_USER,
  BLACK_KITE_COMPANY,
  MITRE_ATTACK_TECHNIQUE,
  MITRE_ATTACK_MATRIX,
  MITRE_ATTACK_TACTIC,
  MITRE_ATTACK_MITIGATION,
  MITRE_ATTACK_GROUP,
  MITRE_ATTACK_SOFTWARE,
  MITRE_ATTACK_DATA_SOURCE,
  CUSTOM_RESOURCE_ENTRY,
  CUSTOM_RESOURCE_TYPE,

  // TRAINING - Module Not Yet Implemented

  // VENDOR
  VENDOR,
  VENDOR_CERTIFICATION,
  VENDOR_CONTROL,
  VENDOR_CONTROL_CATEGORY,
  VENDOR_DOCUMENT,
  VENDOR_REVIEW,
  VENDOR_SOLUTION,
} = resources;

/**
 * The default structured object for User Role Config permissions.
 * @type {{functions: {}, resources: {}, modules: {}}}
 */
export const roleConfigDefault = {
  modules: {
    [ACCOUNT_MANAGER]: true,
    [ACTION_CENTER]: true,
    [ADMINISTRATOR]: false,
    [COMPLIANCE]: false,
    [DEVELOPER]: false,
    [HELP_CENTER]: false,
    [INCIDENT_RESPONSE]: false,
    [METRICS]: false,
    [ORGANIZATION_MANAGER]: false,
    [GOVERNANCE]: false,
    [REPORTS]: false,
    [RISK]: false,
    [VULNERABILITIES]: false,
    [TOOLS]: false,
    [VENDORS]: false,
  },

  resources: {
    // ACCOUNT_MANAGER
    [ACCOUNT_DASHBOARD]: {
      name: "Personal Dashboard",
      description: "A User's Personal Dashboard page",
      module: ACCOUNT_MANAGER,
      operations: ["read"],
    },
    [PREFERENCES]: {
      name: "Account Preferences",
      description: "User Preferences such as interface settings and login options",
      module: ACCOUNT_MANAGER,
      operations: ["read", "update"],
    },

    // ADMINISTRATOR
    [BLUEPRINT_TEMPLATE]: {
      name: "Blueprint Template",
      description: "A Cybersecurity Blueprint template that can be selected by an Organization",
      module: ADMINISTRATOR,
    },
    [CISSP_REVIEW]: {
      name: "CISSP Review",
      description:
        "A special kind of Vendor Review platform by a Rivial Platform Admin. These can be shared with Organizations or external applications",
      module: ADMINISTRATOR,
    },
    [CONTROL_FRAMEWORK_TEMPLATE]: {
      name: "Control Framework Template",
      description: "Compliance Control Frameworks such as ACET or PCI that can be used by Organizations",
      module: ADMINISTRATOR,
    },
    [CONTROL_TEMPLATE]: {
      name: "Control Template",
      description: "Compliance Control Templates that can be used by Organizations",
      module: ADMINISTRATOR,
    },
    [EVIDENCE_TEMPLATE]: {
      name: "Evidence Template",
      description: "Compliance Evidence Templates that can be used by Organizations",
      module: ADMINISTRATOR,
    },
    [OPERATION_TEAM]: {
      name: "Operation Team",
      description: "A group of Users that may be granted access to certain Roles in various Organizations",
      module: ADMINISTRATOR,
    },
    [ORGANIZATION]: {
      name: "Organization",
      description: "An entity using the Rivial Data Security Platform",
      module: ADMINISTRATOR,
    },
    [ROLE_TEMPLATE]: {
      name: "Role Template",
      description: "Role Templates that can be used by an Organization",
      module: ADMINISTRATOR,
    },
    [SYSTEM_TEMPLATE]: {
      name: "System Template",
      description: "Information System Templates that can be used by an Organization",
      module: ADMINISTRATOR,
    },
    [RISK_CONTROL_CATEGORY_TEMPLATE]: {
      name: "Risk Control Category Template",
      description: "Risk Control Category Templates that can be used by an Organization",
      module: ADMINISTRATOR,
    },
    [RISK_CONTROL_TEMPLATE]: {
      name: "Risk Control Template",
      description: "Risk Control Templates that can be used by an Organization",
      module: ADMINISTRATOR,
    },
    [KEY_RISK_INDICATOR_TEMPLATE]: {
      name: "Key Risk Indicator Template",
      description: "Key Risk Indicator Templates that can be used by an Organization",
      module: ADMINISTRATOR,
    },
    [THREAT_TEMPLATE]: {
      name: "Threat Template",
      description: "Threat Templates that can be used by an Organization",
      module: ADMINISTRATOR,
    },
    [INFORMATION_ASSET_TEMPLATE]: {
      name: "Information Asset Template",
      description: "Information Asset Templates that can be used by an Organization",
      module: ADMINISTRATOR,
    },
    [CUSTOM_WIDGET_TEMPLATE]: {
      name: "Custom Widget Template",
      description: "Custom Widget Templates that can be used by an Organization",
      module: ADMINISTRATOR,
    },
    [CUSTOM_QUERY_TEMPLATE]: {
      name: "Custom Query Template",
      description: "Custom Query Templates that can be used by an Organization",
      module: ADMINISTRATOR,
    },

    // COMPLIANCE
    [AUDIT]: {
      name: "Audit",
      description: "A point in time evaluation of an Organization’s security Controls",
      module: COMPLIANCE,
    },
    [CONTROL]: {
      name: "Control",
      description: "Something that an Organization must do to be secure. E.g. “Anti-Virus is ran daily”",
      module: COMPLIANCE,
    },
    [CONTROL_FRAMEWORK]: {
      name: "Control Framework",
      description: "A grouping of controls for a certain standardization. E.g. “ACET”",
      module: COMPLIANCE,
    },
    [DOCUMENT]: {
      name: "Document",
      description: "A file that is stored in the Platform",
      module: COMPLIANCE,
    },
    [ARTIFACT]: {
      name: "Artifact",
      description: "An annotated PDF file generated by the Platform representing a point in time Evidence submission",
      module: COMPLIANCE,
    },
    [EVIDENCE]: {
      name: "Evidence",
      description: "An Item that shows that Security Controls(s) are in place. E.g. “Anti-Virus Monitoring Report”",
      module: COMPLIANCE,
    },
    [COMPLIANCE_CONFIG]: {
      name: "Compliance Configuration",
      description: "Settings for compliance module",
      module: COMPLIANCE,
    },

    // DEVELOPER - No Configurable Resource Permissions
    [FEEDBACK]: {
      name: "Feedback",
      description: "A bug or suggestion that a User sends to the development team",
      module: DEVELOPER,
    },

    // INCIDENT_RESPONSE
    [CLASSIFICATION_TIER]: {
      name: "Classification Tier",
      description: "Severity Level and Escalation Path for Incidents",
      module: INCIDENT_RESPONSE,
    },
    [DETECTION]: {
      name: "Detection",
      description: "A process or system used to detect Incident occurrences",
      module: INCIDENT_RESPONSE,
    },
    [EXERCISE]: {
      name: "Exercise",
      description: "A collaboration with response teams to test out Incident Response processes",
      module: INCIDENT_RESPONSE,
    },
    [INCIDENT]: {
      name: "Incident",
      description: "An unwanted security event that happened to an Organization",
      module: INCIDENT_RESPONSE,
    },
    [PLAYBOOK]: {
      name: "Playbook",
      description: "A customized set of Response Procedures for responding to specific incident scenarios",
      module: INCIDENT_RESPONSE,
    },
    [RESPONSE_TEAM]: {
      name: "Response Team",
      description: "A group consisting of Points of Contact that can be assigned to Incidents and Exercises",
      module: INCIDENT_RESPONSE,
    },
    [RESPONSE_PLAN]: {
      name: "Incident Response Plan",
      description: "Consists of the Organization's entire Incident Response module",
      module: INCIDENT_RESPONSE,
    },

    // HELP_CENTER
    [HELP_ARTICLE]: {
      name: "Help Article",
      description: "A how-to article to aid in the use of the Platform",
      module: HELP_CENTER,
      operations: ["read"],
    },

    // METRICS
    [KEY_PERFORMANCE_INDICATOR]: {
      name: "Key Performance Indicator",
      description: "A logical expression that passes/fails depending on Metric values",
      module: METRICS,
    },
    [METRIC]: {
      name: "Metric",
      description: "A single quantifiable unit of measure",
      module: METRICS,
    },
    [METRIC_TYPE]: {
      name: "Metric Type",
      description: "The configuration item that allows the Platform to gather Metric data",
      module: METRICS,
    },

    // ORGANIZATION_MANAGER
    [AUTOMATION]: {
      name: "Automation",
      description: "A configurable set of Triggers, Conditions, and Automation steps",
      module: ORGANIZATION_MANAGER,
    },
    [CUSTOM_DASHBOARD]: {
      name: "Custom Dashboard",
      description: "A configurable dashboard",
      module: ORGANIZATION_MANAGER,
      operations: ["create", "update", "delete"],
    },
    [CUSTOM_QUERY]: {
      name: "Custom Query",
      description: "A configurable database query to be used in reports or widgets",
      module: ORGANIZATION_MANAGER,
    },
    [CUSTOM_WIDGET]: {
      name: "Custom Widget",
      description: "A configurable widget to be used in reports or dashboards",
      module: ORGANIZATION_MANAGER,
    },
    [DEPARTMENT]: {
      name: "Departments",
      description: "A single business unit inside of an Organization",
      module: ORGANIZATION_MANAGER,
    },
    [QUESTIONNAIRE]: {
      name: "Questionnaire",
      description: "A set of questions that a Point of Contact should answer",
      module: ORGANIZATION_MANAGER,
    },
    [QUESTIONNAIRE_ASSIGNEE]: {
      name: "Questionnaire Assignee",
      description: "A Point of Contact that is assigned a Questionnaire",
      module: ORGANIZATION_MANAGER,
    },
    [EMAIL_TEMPLATE]: {
      name: "Email Template",
      description: "A customized email that may be used when sending out notifications",
      module: ORGANIZATION_MANAGER,
    },
    [JOB]: {
      name: "Job",
      description: "A backend process ran by the Platform. Used to track logs and execution of certain events",
      module: ORGANIZATION_MANAGER,
    },
    [LOCATION]: {
      name: "Location",
      description: "A place that is important to the Organization",
      module: ORGANIZATION_MANAGER,
    },
    [POINT_OF_CONTACT]: {
      name: "Point of Contact",
      description: "A person that is important to the Organization",
      module: ORGANIZATION_MANAGER,
    },
    [ROLE]: {
      name: "Role",
      description: "Applied to User Accounts, configures Module, Resource, and Function access for the Rivial Platform",
      module: ORGANIZATION_MANAGER,
    },
    [USER]: {
      name: "User Account",
      description: "The behind-the-scenes User Account information, related to a Point of Contact",
      module: ORGANIZATION_MANAGER,
    },
    [BRANDING]: {
      name: "Organization Brand Assets",
      description: "Content representing the Organization identity such as the Organization logo",
      module: ORGANIZATION_MANAGER,
      operations: ["update"],
    },

    // GOVERNANCE
    [ACTION_ITEM]: {
      name: "Action Item",
      description:
        "A specific task that an Organization or Point of Contact should perform to improve the security program. Usually in response to a Recommendation. E.g. “Complete June 2021 Training”",
      module: GOVERNANCE,
    },
    [TASK]: {
      name: "Task",
      description: "A unit of work that needs to be completed.",
      module: GOVERNANCE,
    },
    [BLUEPRINT]: {
      name: "Cybersecurity Blueprint",
      description: "A high level overview of an Organization’s security program",
      module: GOVERNANCE,
    },
    [IMPLEMENTATION_LEVEL]: {
      name: "Implementation Level",
      description: "A specific Program Section implementation level, with corresponding RACI indicators",
      module: GOVERNANCE,
    },
    [MEETING]: {
      name: "Meeting",
      description: "Points of Contact getting together to discuss parts of the security program",
      module: GOVERNANCE,
    },
    [OBSERVATION]: {
      name: "Observation",
      description:
        "A neutral bias observation of something in an Organization’s security program that may need addressing. Important Observations may be elevated to a ‘Finding’. E.g. “No monthly training is performed”",
      module: GOVERNANCE,
    },
    [POLICY]: {
      name: "Policy",
      description:
        "A document that outlines rules and procedures that an Organization must follow. E.g. “Information Security Policy”",
      module: GOVERNANCE,
    },
    [PROGRAM_ELEMENT]: {
      name: "Program Element",
      description: "A general block of an Organization’s security program. E.g. “Compliance”, “Risk Assessment”",
      module: GOVERNANCE,
    },
    [PROGRAM_SECTION]: {
      name: "Program Section",
      description: "A focused part of a Program Element. E.g. “Compliance - Audits”",
      module: GOVERNANCE,
    },
    [RECOMMENDATION]: {
      name: "Recommendation Item",
      description:
        "A suggestion on how to improve an Organization’s security program. Usually in response to an Observation. E.g. “Implement a Monthly Training program”",
      module: GOVERNANCE,
    },
    [SOURCE]: {
      name: "Source",
      description: "The place or entity that has led to a Finding or an Observation",
      module: GOVERNANCE,
    },

    // REPORTS
    [REPORT]: {
      name: "Report",
      description: "A Report document regarding the Organization's security program",
      module: REPORTS,
    },

    // RISK
    [INFORMATION_ASSET]: {
      name: "Information Asset",
      description: "Represents a type of data that is evaluated in the Risk Assessment. E.g. 'Corporate Data'",
      module: RISK,
    },
    [INFORMATION_SYSTEM]: {
      name: "System",
      description:
        "An application, process, or physical device that interacts with secure information. E.g. “Email Server”",
      module: RISK,
    },
    [KEY_RISK_INDICATOR]: {
      name: "Key Risk Indicator",
      description: "Represents a bad thing that could happen to an Organization",
      module: RISK,
    },
    [LOSS_TOLERANCE]: {
      name: "Risk Tolerance",
      description: "Represents an Organization's appetite for Risk",
      module: RISK,
    },
    [RISK_CHANGE]: {
      name: "Risk Change",
      description:
        "A point in time change to an Organization that impacts their Risk Assessment data. E.g. “Asset Size Increase”",
      module: RISK,
    },
    [RISK_CONFIG]: {
      name: "Risk Configuration",
      description: "Settings such as Number of Employees, Asset Size, and Number of Customers/Members",
      module: RISK,
    },
    [RISK_CONTROL]: {
      name: "Risk Control",
      description: "A single security control evaluated in the Risk Assessment",
      module: RISK,
    },
    [RISK_CONTROL_CATEGORY]: {
      name: "Risk Control Category",
      description: "A set of Controls that mitigate a certain Risk for an Organization",
      module: RISK,
    },
    [THREAT]: {
      name: "Threat",
      description: "A specific risk scenario",
      module: RISK,
    },

    // TESTING
    [ASSESSMENT]: {
      name: "Assessment",
      description: "A point in time Vulnerability Scan across a range of targets",
      module: VULNERABILITIES,
    },
    [SECURITY_APPLIANCE]: {
      name: "Security Appliance",
      description: "A physical appliance on an internal network that helps facilitate security scans",
      module: VULNERABILITIES,
    },
    [TARGET]: {
      name: "Target",
      description: "A specific IP address or Host that is involved in a Security Scan. E.g. “Office Guest Computer”",
      module: VULNERABILITIES,
    },
    [VULNERABILITY]: {
      name: "Vulnerability",
      description:
        "A specific finding from a Security Scan, may be associated with one or multiple Targets. E.g. “Windows Server is running a legacy version”",
      module: VULNERABILITIES,
    },

    // TOOLS
    [TOOL]: {
      name: "Tool",
      description: "A 3rd party integration utility",
      module: TOOLS,
    },
    [KNOW_BE_4_TRAINING]: {
      name: "KnowBe4 Training",
      description: "KnowBe4 security training",
      module: TOOLS,
      operations: ["read"],
    },
    [KNOW_BE_4_PHISHING]: {
      name: "KnowBe4 Phishing",
      description: "KnowBe4 phishing tests",
      module: TOOLS,
      operations: ["read"],
    },
    [KNOW_BE_4_USER]: {
      name: "KnowBe4 User",
      description: "KnowBe4 users",
      module: TOOLS,
      operations: ["read"],
    },
    [BLACK_KITE_COMPANY]: {
      name: "BlackKite Company",
      description: "Company information from BlackKite integration",
      module: TOOLS,
      operations: ["read"],
    },
    [MITRE_ATTACK_TECHNIQUE]: {
      name: "Mitre Attack Technique",
      description:
        "Techniques represent 'how' an adversary achieves a tactical goal by performing an action. For example, an adversary may dump credentials to achieve credential access.",
      module: TOOLS,
      operations: ["read"],
    },
    [MITRE_ATTACK_MATRIX]: {
      name: "Mitre Attack Matrix",
      description:
        "The Matrix contains information for the following platforms: Windows, macOS, Linux, PRE, Azure AD, Office 365, Google Workspace, SaaS, IaaS, Network, Containers",
      module: TOOLS,
      operations: ["read"],
    },
    [MITRE_ATTACK_TACTIC]: {
      name: "Mitre Attack Tactic",
      description:
        'Tactics represent the "why" of an ATT&CK technique or sub-technique. It is the adversary\'s tactical goal: the reason for performing an action. For example, an adversary may want to achieve credential access.',
      module: TOOLS,
      operations: ["read"],
    },
    [MITRE_ATTACK_DATA_SOURCE]: {
      name: "Mitre Attack Data Source",
      description:
        "Data sources represent the various subjects/topics of information that can be collected by sensors/logs. Data sources also include data components, which identify specific properties/values of a data source relevant to detecting a given ATT&CK technique or sub-technique.",
      module: TOOLS,
      operations: ["read"],
    },
    [MITRE_ATTACK_MITIGATION]: {
      name: "Mitre Attack Mitigation",
      description:
        "Mitigations represent security concepts and classes of technologies that can be used to prevent a technique or sub-technique from being successfully executed.",
      module: TOOLS,
      operations: ["read"],
    },
    [MITRE_ATTACK_GROUP]: {
      name: "Mitre Attack Group",
      description:
        "Groups are sets of related intrusion activity that are tracked by a common name in the security community.",
      module: TOOLS,
      operations: ["read"],
    },
    [MITRE_ATTACK_SOFTWARE]: {
      name: "Mitre Attack Software",
      description:
        "Software is a generic term for custom or commercial code, operating system utilities, open-source software, or other tools used to conduct behavior modeled in ATT&CK.",
      module: TOOLS,
      operations: ["read"],
    },
    [CUSTOM_RESOURCE_TYPE]: {
      name: "Custom Resource Type",
      description: "Custom Resource Types used for Tool integrations",
      module: TOOLS,
      operations: ["read", "create", "update", "delete"],
    },
    [CUSTOM_RESOURCE_ENTRY]: {
      name: "Custom Resource Entry",
      description: "Custom Resource Entries used for Tool integrations",
      module: TOOLS,
      operations: ["read", "create", "update", "delete"],
    },

    // VENDORS
    [VENDOR]: {
      name: "Vendor",
      description: "A entity that an Organization works with that provides services. E.g. “Microsoft”",
      module: VENDORS,
    },
    [VENDOR_CERTIFICATION]: {
      name: "Vendor Certification",
      description:
        "A list of security controls that may be automatically evaluated for a Vendor Review if the Vendor Solution already has a current audit or certification. E.g. “Current PCI certification - automatically satisfies 10 controls”",
      module: VENDORS,
    },
    [VENDOR_CONTROL]: {
      name: "Vendor Control",
      description: "A Security control that is tracked as In Place / Audited for a Vendor",
      module: VENDORS,
    },
    [VENDOR_CONTROL_CATEGORY]: {
      name: "Vendor Control Category",
      description: "A grouping of Vendor Controls of a particular type",
      module: VENDORS,
    },
    [VENDOR_DOCUMENT]: {
      name: "Vendor Document",
      description: "A document that has been uploaded as part of a Vendor Review",
      module: VENDORS,
    },
    [VENDOR_REVIEW]: {
      name: "Vendor Review",
      description: "A point in time evaluation of the security of a particular Vendor Solution",
      module: VENDORS,
    },
    [VENDOR_SOLUTION]: {
      name: "Vendor Solution",
      description: "A specific service or product from a Vendor. E.g. “Office 365”",
      module: VENDORS,
    },
  },

  functions: {},
};

export default roleConfigDefault;
