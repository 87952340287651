import { createInlineText, createText } from "@rivial-security/report-utils";

import type { InlineText, TextBlock } from "@rivial-security/report-utils";

export const generateOverallRiskStatement = (rating: string): TextBlock[] => {
  const inlineText: InlineText[] = [];

  inlineText.push(createInlineText("Overall Cybersecurity Risk: ", undefined, { fontSize: 18 }));

  const ratingStyle = { fontSize: 18, bold: true };
  if (rating) {
    switch (rating) {
      case "high":
        inlineText.push(
          createInlineText("High", undefined, {
            ...ratingStyle,
            fontColor: "#F30000FF",
          }),
        );
        break;
      case "mediumHigh":
        inlineText.push(
          createInlineText("Medium - High", undefined, {
            ...ratingStyle,
            fontColor: "#F36000FF",
          }),
        );
        break;
      case "medium":
        inlineText.push(
          createInlineText("Medium", undefined, {
            ...ratingStyle,
            fontColor: "#F3BB00FF",
          }),
        );
        break;
      case "lowMedium":
        inlineText.push(
          createInlineText("Low - Medium", undefined, {
            ...ratingStyle,
            fontColor: "#1DE900FF",
          }),
        );
        break;
      case "low":
        inlineText.push(
          createInlineText("Low", undefined, {
            ...ratingStyle,
            fontColor: "#00ABF3FF",
          }),
        );
        break;
      default:
      //pass
    }
  }

  return [createText([...inlineText])];
};
