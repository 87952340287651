import type { Dispatch, SetStateAction } from "react";

import { isNonEmptyArray } from "@rivial-security/func-utils";

import { getObjectPropertyString } from "../../../../../utils/Functions/Array/getObjectPropertyString";
import { useRefState } from "../../../../functional/useRefState";
import { useSetAsyncData } from "../../../../functional/useSetAsyncData";

import { getQueryCustomFieldData } from "./functions/getQueryCustomFieldData";

import type { EnhancedCustomField } from "../../../../../typedefs/Utility/customFields";

export interface UseQueryCustomFieldsParams {
  customFields: EnhancedCustomField[];
  externalCustomFieldData?: Record<string, Record<string, unknown>[]>;
  organizationID: string;
  updateCustomFieldData: ({ queryCustomFieldData }: { queryCustomFieldData: RequestByFieldName }) => void;
  loadingFields: string[];
  setLoadingFields: Dispatch<SetStateAction<string[]>>;
  columnVisibilityModel: Record<string, boolean>;
}

export interface UseQueryCustomFieldsResult {
  isLoading: boolean;
  queryDataRef: {
    current: Record<string, Record<string, unknown>[]> | undefined;
  };
}

export type RequestByFieldName = Record<string, Record<string, unknown>[]>;

export const useQueryCustomFields = ({
  customFields,
  externalCustomFieldData,
  organizationID,
  updateCustomFieldData,
  loadingFields,
  setLoadingFields,
  columnVisibilityModel,
}: UseQueryCustomFieldsParams): UseQueryCustomFieldsResult => {
  const [queryData, setQueryData, queryDataRef] = useRefState(externalCustomFieldData ?? {});

  const isMissingFromData = (customField: EnhancedCustomField) => {
    const fieldName = customField?.name;
    const type = customField.type;
    if (!fieldName || !type) {
      return false;
    }

    const isQueryCustomField = type === "query";
    const isMissingData = fieldName && !queryData[fieldName];
    const isColumnVisible = columnVisibilityModel[fieldName] ?? false;
    const isColumnLoading = loadingFields.includes(fieldName);

    return isQueryCustomField && isMissingData && isColumnVisible && !isColumnLoading;
  };

  const getMissingData = async ({
    missingQueryCustomFields,
  }: {
    missingQueryCustomFields: EnhancedCustomField[];
  }): Promise<RequestByFieldName> => {
    const requestByFieldName: Record<string, Promise<Record<string, unknown>[]>> = {};
    for (const customField of missingQueryCustomFields) {
      const fieldName = customField.name;

      if (!fieldName) {
        continue;
      }

      requestByFieldName[fieldName] = getQueryCustomFieldData({ customField, organizationID });
    }

    const resultsByFieldName: RequestByFieldName = {};

    for (const fieldName in requestByFieldName) {
      const requestedFieldName = await requestByFieldName[fieldName];

      resultsByFieldName[fieldName] = requestedFieldName ?? [];
    }

    return resultsByFieldName;
  };

  const { isLoading } = useSetAsyncData({
    getData: async () => {
      const missingQueryCustomFields = customFields.filter(isMissingFromData);
      if (!isNonEmptyArray(missingQueryCustomFields)) {
        return { newData: null };
      }

      const missingFieldNames: string[] = [];
      for (const customField of missingQueryCustomFields) {
        const name = customField?.name;
        if (name) {
          missingFieldNames.push(name);
        }
      }

      setLoadingFields?.((fields) => {
        return [...fields, ...missingFieldNames];
      });

      const newData = await getMissingData({ missingQueryCustomFields });
      return { newData, missingFieldNames };
    },
    setData: ({ missingFieldNames, newData }: { missingFieldNames: string[]; newData: RequestByFieldName }) => {
      if (!newData) return;

      setLoadingFields?.((fields) => {
        return fields.filter((field) => !missingFieldNames.includes(field));
      });

      setQueryData((oldData: RequestByFieldName) => {
        const combinedData = {
          ...oldData,
          ...newData,
        };

        updateCustomFieldData({
          queryCustomFieldData: combinedData,
        });

        return combinedData;
      });
    },
    dependencies: [getObjectPropertyString({ array: customFields, propertyName: ["name"] }), columnVisibilityModel],
  });

  return {
    isLoading,
    queryDataRef,
  };
};
