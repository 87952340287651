import { isNonEmptyArray } from "@rivial-security/func-utils";
import { useState } from "react";
import { v4 as uuid } from "uuid";

import type { Target, TargetFindingLink } from "@rivial-security/schema-types";

import useDidMountEffect from "@hooks/functional/useDidMountEffect";

import { emptyPlaceholder } from "../../../../../utils/GenericComponents/Placeholders";

import { getTargetsBasedOnFixedRatio } from "./functions/getTargetsBasedOnFixedRatio";
import { getVulnerabilitySeenTargets } from "./functions/getVulnerabilitySeenTargets";

import type { VulnerabilityTarget } from "./functions/getTargetsBasedOnFixedRatio";

export interface VulnerabilityTargetsProps {
  item: {
    targetsCustom?: Target[];
    targets?: {
      items: TargetFindingLink[];
    };
  };
}

/**
 * Display component for VulnerabilityTargets
 * @param {object} item - vulnerability group item
 */
const VulnerabilityTargets = ({ item }: VulnerabilityTargetsProps): JSX.Element => {
  const getTargetList = () => {
    const targets = item?.targetsCustom ?? [];
    const targetLinks = item?.targets?.items ?? [];

    const seenTargets = getVulnerabilitySeenTargets({
      targets,
      targetLinks,
    });

    const newTargetList = getTargetsBasedOnFixedRatio({ seenTargets, targets });

    //Sort the targets by name
    newTargetList.sort((a, b) => {
      if (a?.name === b?.name) {
        return 0;
      } else {
        return a?.name > b?.name ? 1 : -1;
      }
    });

    return newTargetList;
  };

  const [targetList, setTargetList] = useState<VulnerabilityTarget[]>(getTargetList());

  useDidMountEffect(() => {
    setTargetList(getTargetList());
  }, [JSON.stringify(item?.targetsCustom), JSON.stringify(item?.targets)]);

  if (isNonEmptyArray(targetList)) {
    return (
      <div>
        {targetList.map((target, index) => {
          const { name, color } = target;
          const isLast = index === targetList.length - 1;
          return (
            <span key={uuid()} style={{ color }}>
              {name}
              {!isLast && ", "}
            </span>
          );
        })}
      </div>
    );
  }

  return emptyPlaceholder;
};

export default VulnerabilityTargets;
