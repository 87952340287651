import { isLocalhost } from "@utils/Functions/isLocalhost";

import { BIG_BOY_API_LOCALHOST_URL } from "../../../env-config";
import { ErrorLogger, InfoLogger } from "../../EventLogger";
import { GetQuery } from "../Graphql/GetQuery";

type InputType = Record<string, unknown>;

const fargateApiQuery = /* GraphQL */ `
  query FargateApi($input: AWSJSON) {
    fargateApi(input: $input)
  }
`;

/**
 * Sends a request to the local Fargate API endpoint.
 * @param input - The parameters to send to the API.
 * @returns The API response as a string.
 */
const callLocalFargateApi = async (input: InputType): Promise<string | null> => {
  try {
    const response = await fetch(BIG_BOY_API_LOCALHOST_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ input }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    InfoLogger("Local Fargate API response:", data);
    return data;
  } catch (error) {
    ErrorLogger("Local Fargate API error:", error);
    return null;
  }
};

/**
 * Sends a request to the remote Fargate API via GraphQL.
 * @param input - The parameters to send to the API.
 * @returns The API response as a string.
 */
const callRemoteFargateApi = async (input: InputType): Promise<string | null> => {
  try {
    return await GetQuery({
      query: fargateApiQuery,
      variables: { input: JSON.stringify(input) },
    });
  } catch (error) {
    ErrorLogger("Remote Fargate API error:", error);
    return null;
  }
};

/**
 * Helper function to send a request to the Fargate API.
 * Depending on the environment, it either uses the local endpoint or the remote GraphQL API.
 *
 * @param input - Parameters to send to the API.
 * @returns The API response as a string.
 */
export const fargateApi = async ({ input }: { input: InputType }): Promise<string | null> => {
  if (!input) {
    ErrorLogger("[fargateApi] Missing the input object");
    return "";
  }

  InfoLogger("Sending request to Fargate API. Input:", JSON.stringify(input));

  return isLocalhost() ? await callLocalFargateApi(input) : await callRemoteFargateApi(input);
};
