import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { GENERIC_FIELD_TYPES } from "../../../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

/**
 * Displays the Strength Rating value for a Risk Control
 * @param {object} item - the risk control item
 * @param {number} item.strengthRating - decimal value representing the strength rating as a percentage
 * @param {function} resetFunction - used to re-query item after update
 * @param {boolean} disableTooltip - if true will hide the help tool tip
 * @param {object} ...props - any other props to pass to GenericEditFieldV3
 * @returns {JSX.Element}
 * @constructor
 */
const StrengthRating = ({ item, resetFunction, disableTooltip, ...props }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL;
  const { updateMutation: updateRiskControl } = generateGraphql("RiskControl", ["implementationRating"]);

  return (
    <span style={{ width: "100%" }}>
      <GenericEditFieldV3
        item={item}
        field="strengthRating"
        mutation={updateRiskControl}
        module={module}
        resource={resource}
        inputType={GENERIC_FIELD_TYPES.PERCENTAGE}
        resetFunction={resetFunction}
        allowEmpty={false}
        tooltip={
          !disableTooltip
            ? "Maximum percentage that this control will reduce the likelihood of a risk occurring."
            : undefined
        }
        {...props}
      />
    </span>
  );
};

export default withOrganizationCheck(StrengthRating);
