import { gql } from "@graphql/types";

export const AUDITS_BY_CONTROL_FRAMEWORK = gql(`
query auditsByControlFramework($controlFrameworkId: ID!, $limit: Int, $nextToken: String, $sort: AuditSort) {
  auditsByControlFramework(controlFrameworkId: $controlFrameworkId, limit: $limit, nextToken: $nextToken, sort: $sort) {
    ownerGroup
    items {
      value: id
      label: name
      ownerGroup
    }
  }
}
`);
