import { generateGraphql } from "@rivial-security/generategraphql";

import type { AssessmentTargetLink, AssessmentVulnerabilityLink } from "@rivial-security/schema-types";

import { ErrorLogger } from "@utils/EventLogger";

import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";

import { getAssessmentTargetLinksFalsePositives } from "./getAssessmentTargetLinksFalsePositives";

/**
 *  Gets all false positives for an assessment and gets the data in chunks of 200 items per request
 */
export const getAssessmentVulnerabilityLinksFalsePositives = async (
  assessmentID: string,
): Promise<FalsePositiveLink[]> => {
  const falsePositives: FalsePositiveLink[] = [];

  try {
    const assessmentTargets = await getAssessmentTargetLinksFalsePositives(assessmentID);
    let nextToken: string | null = "";
    do {
      await ListQueryBy({
        query: assessmentVulnerabilityLinksFalsePositivesByAssessmentID,
        variables: { assessmentID },
        nextToken,
        limit: 200,
        setNextToken: (token: string | null) => {
          nextToken = token;
        },
        getDataByNextToken: (vulnerabilitiesData: AssessmentVulnerabilityLink[]) => {
          processFindingsData({ vulnerabilitiesData, falsePositives, assessmentTargets });
        },
      });
    } while (nextToken);
  } catch (error) {
    ErrorLogger("Error getting false positives:", { error });
  }

  return falsePositives;
};

export interface FalsePositiveLink {
  targetIp: string;
  targetHostName: string;
  vulnerabilityName: string;
  falsePositive?: boolean | null;
}

interface ProcessFindingsDataParams {
  vulnerabilitiesData: AssessmentVulnerabilityLink[];
  falsePositives: FalsePositiveLink[];
  assessmentTargets: AssessmentTargetLink[];
}

const processFindingsData = ({
  vulnerabilitiesData,
  falsePositives,
  assessmentTargets,
}: ProcessFindingsDataParams): void => {
  vulnerabilitiesData.forEach((link) => {
    const targetFindingLinks = link.vulnerability?.targets?.items ?? [];
    targetFindingLinks.forEach((targetLink) => {
      const foundInAssessment = assessmentTargets.some((at) => at.targetID === targetLink?.target?.id);
      if (!foundInAssessment) {
        return;
      }

      falsePositives.push({
        targetIp: targetLink?.target?.ip ?? "",
        targetHostName: targetLink?.target?.hostName ?? "",
        vulnerabilityName: link?.vulnerability?.name ?? "",
        ...targetLink,
      });
    });
  });
};

const { listByQuery: assessmentVulnerabilityLinksFalsePositivesByAssessmentID } = generateGraphql(
  "AssessmentVulnerabilityLink",
  ["assessmentID", "vulnerability"],
  {
    vulnerability: `{
      name
      targets(limit: 300) {
        items {
          id
          target {
            id
            ip
            hostName
          }
          vulnerability {
            id
            name
          }
          falsePositive
        }
        nextToken
      }
    }`,
  },
  {
    indexName: "listAssessmentVulnerabilityLinksByAssessmentID",
    partitionKey: "assessmentID",
    partitionKeyType: "ID",
  },
);
