import type { ReactElement } from "react";

import { Badge } from "reactstrap";

import type { VendorCertification } from "schema-types";

export interface CertificationNameProps {
  vendorCertification?: VendorCertification;
}

const CertificationName = ({ vendorCertification }: CertificationNameProps): ReactElement => {
  if (vendorCertification) {
    return (
      <Badge color="success">
        <i className={"icon-badge"} /> {vendorCertification.name || "Unknown Certification"}
      </Badge>
    );
  } else {
    return <span style={{ fontStyle: "italic" }}>N/A</span>;
  }
};

export default CertificationName;
