import Grid from "@mui/material/Grid";
import { generateGraphql } from "@rivial-security/generategraphql";
import { TOOL_TYPES } from "@rivial-security/integration-utils";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import { useForm } from "../../../../../hooks/views/useForm/useForm";
import { useModal } from "../../../../../hooks/views/useModal";
import Dashboard from "../../../../../utils/GenericComponents/Dashboard";
import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";
import PermissionsOverlay from "../../../../../utils/Overlays/PermissionsOverlay";
import CheckTool from "../../../Tool/components/CheckTool";
import { fetchData } from "../../../Tool/functions/fetchData";
import BlackKiteBreachIndex from "../customFields/BlackKiteBreachIndex";
import BlackKiteComplianceRating from "../customFields/BlackKiteComplianceRating";
import BlackKiteCyberRating from "../customFields/BlackKiteCyberRating";
import BlackKiteDomainNamesList from "../customFields/BlackKiteDomainNamesList";
import BlackKiteEcosystems from "../customFields/BlackKiteEcosystems";
import BlackKiteGrade from "../customFields/BlackKiteGrade";
import BlackKiteRansomwareIndex from "../customFields/BlackKiteRansomwareIndex";
import { getBlackKiteCompany } from "../graphql/getBlackKiteCompany";

import type { FetchDataResponse } from "../../../Tool/functions/fetchData";

export interface VendorBlackKiteCompanyProps {
  item: {
    blackKiteCompanyID: string;
  };
  organizationID: string;
  resetFunction?: () => void;
}

export interface BlackKiteResponse {
  CompanyName?: string;
  DomainNames?: string[];
  GradeLetter?: string;
  Ecosystems?: {
    EcosystemName: string;
  }[];
  CyberRating?: number;
  ComplianceRating?: number;
  BreachIndex?: number;
  RansomwareIndex?: number;
}

/**
 * @description Display Black Kite company information
 */
const VendorBlackKiteCompany = ({ item, organizationID, resetFunction }: VendorBlackKiteCompanyProps): JSX.Element => {
  const toolType = TOOL_TYPES.BLACK_KITE;
  const module = modules.TOOLS;
  const resource = resources.BLACK_KITE_COMPANY;

  const [blackKiteResponse, setBlackKiteResponse] = useState<BlackKiteResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [noIntegration, setNoIntegration] = useState(false);

  useEffect(() => {
    /**
     * Get Black Kite company information
     */
    if (item?.blackKiteCompanyID) {
      fetchData({
        toolType,
        input: {
          query: getBlackKiteCompany,
          queryInput: {
            id: item?.blackKiteCompanyID,
          },
        },
        organizationID,
      })
        .then((res: FetchDataResponse<unknown>) => {
          setBlackKiteResponse(res as BlackKiteResponse);
          setNoIntegration(false);
        })
        .catch((err) => ErrorLogger(err))
        .finally(() => setIsLoading(false));
    } else if (item && !item.blackKiteCompanyID) {
      /**
       * No integration setup
       */
      setIsLoading(false);
      setNoIntegration(true);
    }
  }, [item]);

  const { updateMutation } = generateGraphql("Vendor", ["blackKiteCompanyID"]);

  const form = useForm({
    item,
    mutation: updateMutation,
    organizationID,
    disableRoleChecking: true,
    typename: "Vendor",
    fieldConfig: {
      blackKiteCompanyID: {
        label: "Black Kite Company ID",
        required: true,
        tooltip: "The ID of the company from the Black Kite Integration",
      },
    },
    callback: () => {
      linkVendorModal.setModalIsOpen(false);
      setIsLoading(true);
      resetFunction?.();
    },
  });

  const linkVendorModal = useModal(
    "Link Vendor to Black Kite Company",
    form?.display,
    <Button className="btn-pill" color="primary" size="sm">
      Add Black Kite Integration
    </Button>,
  );

  return (
    <div>
      {linkVendorModal.modal}
      <PermissionsOverlay module={module} resource={resource} operationType={"read"}>
        <CheckTool toolType={toolType} organizationID={organizationID}>
          <DataLoader
            isLoading={isLoading}
            isEnoughData={!!blackKiteResponse?.CompanyName}
            dataMessage={"Can not find company"}
            callToAction={{
              message: " to update Black Kite Vendor information",
              function: () => linkVendorModal?.setModalIsOpen(true),
            }}
          >
            <Dashboard subTitle={blackKiteResponse?.CompanyName}>
              <div>
                {noIntegration ? (
                  linkVendorModal?.modalButton
                ) : (
                  <div>
                    <Grid container spacing={2}>
                      <Grid item lg={4} xs={4}>
                        <BlackKiteGrade GradeLetter={blackKiteResponse?.GradeLetter} />
                        <br />
                        Websites:
                        <BlackKiteDomainNamesList DomainNames={blackKiteResponse?.DomainNames} />
                        <br />
                        Eco Systems:
                        <BlackKiteEcosystems Ecosystems={blackKiteResponse?.Ecosystems} />
                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <BlackKiteCyberRating CyberRating={blackKiteResponse?.CyberRating} />
                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <BlackKiteComplianceRating ComplianceRating={blackKiteResponse?.ComplianceRating} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={4} xs={4}></Grid>
                      <Grid item lg={4} xs={4}>
                        <BlackKiteBreachIndex BreachIndex={blackKiteResponse?.BreachIndex} />
                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <BlackKiteRansomwareIndex RansomwareIndex={blackKiteResponse?.RansomwareIndex} />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            </Dashboard>
          </DataLoader>
        </CheckTool>
      </PermissionsOverlay>
    </div>
  );
};

export default VendorBlackKiteCompany;
