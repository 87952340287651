import React from "react";

import { NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

import type { NavigationModule } from "@application/Navigation/types";

const ComplianceDashboard = React.lazy(() => import("@views/Compliance/ComplianceDashboard/ComplianceDashboard"));
const AuditReport = React.lazy(() => import("@views/Reports/AuditReport/AuditReport"));
const ControlDetails = React.lazy(() => import("@views/Compliance/Controls/Controls/components/ControlDetails"));
const ControlSetGrid = React.lazy(() => import("@views/Compliance/Controls/ControlSets/components/ControlSetDataGrid"));
const ControlDashboard = React.lazy(() => import("@views/Compliance/Controls/Controls/components/ControlDashboard"));
const ControlSetDetails = React.lazy(
  () => import("@views/Compliance/Controls/ControlSets/components/ControlSetDetails"),
);
const EvidenceList = React.lazy(() => import("@views/Compliance/Evidence/components/EvidenceDataGrid"));
const EvidenceDetails = React.lazy(() => import("@views/Compliance/Evidence/components/EvidenceDetails"));
const ComplianceArtifacts = React.lazy(() => import("@views/Compliance/Documents/components/ComplianceArtifacts"));
const ComplianceReport = React.lazy(() => import("@views/Reports/ComplianceReport/components/ComplianceReport"));
const DocumentDetails = React.lazy(() => import("@views/Compliance/Documents/components/DocumentDetails"));
const ComplianceConfig = React.lazy(() => import("@views/Compliance/ComplianceConfig/components/ComplianceConfig"));
const AuditDetails = React.lazy(() => import("@views/Compliance/Audit/components/AuditDetails"));
const AuditGrid = React.lazy(() => import("@views/Compliance/Audit/components/AuditDataGrid"));

export const complianceNavigation: NavigationModule = {
  moduleName: modules.COMPLIANCE,
  sectionName: NavigationSectionName.SERVICES,
  name: "Compliance",
  path: "/continuous_compliance",
  deprecatedPaths: ["/compliance"],
  defaultRoutePath: "/dashboard",
  icon: IconName.COMPLIANCE,
  routes: [
    {
      path: "/dashboard",
      deprecatedPaths: ["/controls"],
      name: "Compliance Dashboard",
      component: ComplianceDashboard,
      navigationBar: {
        name: "Dashboard",
      },
    },
    {
      path: "/evidence",
      name: "Evidence",
      component: EvidenceList,
    },
    {
      path: "/evidence/:id",
      name: "Evidence Details",
      component: EvidenceDetails,
    },
    {
      path: "/control_dashboard",
      name: "Controls",
      component: ControlDashboard,
    },
    {
      path: "/controls/:id",
      name: "Control Details",
      component: ControlDetails,
    },
    {
      path: "/control_frameworks",
      name: "Control Frameworks",
      component: ControlSetGrid,
      navigationBar: {
        name: "Frameworks",
      },
    },
    {
      path: "/control_frameworks/:id",
      name: "Control Framework Details",
      component: ControlSetDetails,
    },
    {
      path: "/audits",
      name: "IT Audits",
      component: AuditGrid,
      navigationBar: {
        name: "Audits",
      },
    },
    {
      path: "/audits/:id",
      name: "IT Audit Details",
      component: AuditDetails,
    },
    {
      path: "/reports/compliance",
      name: "Compliance Report",
      component: ComplianceReport,
      navigationBar: {
        name: "Reports",
      },
    },
    {
      path: "/artifacts",
      name: "Compliance Artifacts",
      component: ComplianceArtifacts,
      navigationBar: {
        name: "Artifacts",
      },
    },
    {
      path: "/compliance_config",
      name: "Compliance Configuration",
      component: ComplianceConfig,
      navigationBar: {
        name: "Compliance Config",
      },
    },

    {
      path: "/reports/audit",
      name: "IT Audit Report",
      component: AuditReport,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/documents/:id",
      name: "Document Details",
      component: DocumentDetails,
      navigationBar: {
        hidden: true,
      },
    },
  ],
};
