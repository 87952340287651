import React from "react";

import type { Route } from "@application/Layout/hooks/types/Navigation";

const AccountManager = React.lazy(() => import("@views/OrganizationManager/PointOfContacts/components/AccountManager"));

export const generalRoutes: Route[] = [
  {
    path: "/my_dashboard/:id",
    exact: true,
    name: "My Dashboard",
    component: AccountManager,
  },
];
