import { createInlineText, createText } from "@rivial-security/report-utils";

import type { TextBlock } from "@rivial-security/report-utils";

export const generateTypeOfReport = (): TextBlock[] => {
  return [
    createText("Type of Report", undefined, { underline: true }),

    createText([
      createInlineText("☐", undefined, { fontFamily: "MS Gothic" }),
      createInlineText(" SOC 1 Report", undefined, { bold: true }),
      createInlineText(" – Internal controls over financial reporting"),
    ]),
    createText([
      createInlineText("            ", undefined),
      createInlineText("☐", undefined, { fontFamily: "MS Gothic" }),
      createInlineText(" Type I"),
      createInlineText("            ", undefined),
      createInlineText("☐", undefined, { fontFamily: "MS Gothic" }),
      createInlineText(" Type II"),
    ]),

    createText([
      createInlineText("☐", undefined, { fontFamily: "MS Gothic" }),
      createInlineText(" SOC 2 Report", undefined, { bold: true }),
      createInlineText(" – Controls over security, availability, processing integrity, confidentiality/privacy"),
    ]),
    createText([
      createInlineText("            ", undefined),
      createInlineText("☐", undefined, { fontFamily: "MS Gothic" }),
      createInlineText(" Type I"),
      createInlineText("            ", undefined),
      createInlineText("☐", undefined, { fontFamily: "MS Gothic" }),
      createInlineText(" Type II"),
    ]),

    createText([
      createInlineText("☐", undefined, { fontFamily: "MS Gothic" }),
      createInlineText(" Other", undefined, { bold: true }),
      createInlineText(" – PCIDSS Certification"),
    ]),
  ];
};
