import type { ReactElement } from "react";

import type { Vulnerability } from "@rivial-security/schema-types";

import Button from "../../../../components/Atoms/Button/Button";
import { useModal } from "../../../../hooks/views/useModal";

import VulnerabilityRawDataDataGrid from "./VulnerabilityRawDataDataGrid";

const VulnerabilityRawData = ({ item }: { item: Vulnerability }): ReactElement => {
  const modal = useModal(
    "Vulnerability Raw Data",
    <VulnerabilityRawDataDataGrid vulnerabilityID={item?.id} />,
    <Button>Open</Button>,
    {
      width: "80%",
    },
  );

  return <>{modal.modalButton}</>;
};

export default VulnerabilityRawData;
