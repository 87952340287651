import { useEffect, useState } from "react";

import ListView from "../../../../../utils/GenericComponents/Charts/ListView";

import type { BlackKiteResponse } from "../components/VendorBlackKiteCompany";

/**
 * @description Black Kite Domain Names List component
 */
const BlackKiteDomainNamesList = ({ DomainNames }: BlackKiteResponse): JSX.Element => {
  const [list, setList] = useState<{ id: number; text: string }[]>([]);

  useEffect(() => {
    if (Array.isArray(DomainNames) && DomainNames.length > 0) {
      const tempList = DomainNames.map((domain, index) => {
        return {
          id: index,
          text: domain,
        };
      });

      setList(tempList);
    }
  }, [DomainNames]);

  return <ListView list={list} />;
};

export default BlackKiteDomainNamesList;
