import React from "react";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { useSync } from "../../../../hooks/functional/useSync";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { DontHavePermission } from "../../../../utils/AccessControl/components/DontHavePermission";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { deletePointOfContactResponseTeamLink } from "../functions/deletePointOfContactResponseTeamLink";
import { useAttachPointOfContacts } from "../hooks/useAttachPointOfContacts";

/**
 * Custom field for displaying Points of Contact associated with a Response Team
 * @param {object[]} pointOfContactLinks - the pointOfContactLinks field from the response team db object
 * @param {function} resetFunction - function that gets called to update UI when a change occurs
 * @param {string} organizationID - currently selected organization
 * @param {string} [orientation="horizontal"] - "horizontal" or "vertical" arrangement of POCs
 * @param {object} item - the response team input object (passed by grid automatically, if used in a grid)
 * @param {boolean} [disableEdits = false] - forces add/remove/edit operations to be hidden
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContacts = ({
  pointOfContactLinks,
  resetFunction,
  organizationID,
  orientation = "horizontal",
  item,
  disableEdits = false,
  ...props
}) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.RESPONSE_TEAM;

  const [pointOfContacts] = useSync(pointOfContactLinks, () => {
    return pointOfContactLinks?.items?.map((item) => {
      return {
        ...item.pointOfContact,
        pointOfContactResponseTeamLink: item,
      };
    });
  });

  const checkPermissions = useCheckPermissions({ module, resource });

  const attachPointOfContacts = useAttachPointOfContacts({
    organizationID,
    item,
    resetFunction,
  });

  const addTeamMembers = (
    <ListGroupItem color="success" action tag="button" onClick={() => attachPointOfContacts.setModalIsOpen(true)}>
      <i className="icon-plus" /> Add Team Members
      {attachPointOfContacts.modal}
    </ListGroupItem>
  );

  return (
    <div>
      <ListGroup horizontal={props.horizontal}>
        {checkPermissions.resource.read ? (
          <div
            style={{
              display: "flex",
              flexDirection: orientation === "horizontal" ? "row" : "column",
              flexWrap: "wrap",
            }}
          >
            {pointOfContacts?.length > 0 ? (
              pointOfContacts?.map((item, index) => (
                <ListGroupItem style={{ margin: "0.5em" }} key={`meeting_contacts${index}`}>
                  <Row>
                    {!props.horizontal && (
                      <Col lg={3}>
                        <img
                          alt="avatar"
                          src="./assets/img/avatars/person.png"
                          style={{
                            verticalAlign: "middle",
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            background: "white",
                          }}
                        />
                      </Col>
                    )}
                    <Col>
                      <div>
                        <strong>{item && `${item.firstName} ${item.lastName}`}</strong>
                        {!disableEdits && checkPermissions.resource.update && (
                          <div className="float-right" style={{ display: "inline" }}>
                            <Button
                              className="float-right"
                              close
                              onClick={async () => {
                                if (
                                  window.confirm(
                                    `Are you sure you want to remove ${
                                      item && item.firstName && item.lastName
                                        ? `${item.firstName} ${item.lastName}`
                                        : "this point of contact"
                                    }?`,
                                  )
                                ) {
                                  await deletePointOfContactResponseTeamLink(item.pointOfContactResponseTeamLink);
                                  resetFunction?.();
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <strong>{item && item.title}</strong>
                      </div>
                      <div>
                        <strong>{item && item.email}</strong>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))
            ) : (
              <small style={{ fontStyle: "italic" }}>No Points of Contact</small>
            )}
            {orientation === "vertical" && !disableEdits && checkPermissions.resource.update && addTeamMembers}
          </div>
        ) : (
          <DontHavePermission module={module} resource={resource} />
        )}
        {orientation === "horizontal" && !disableEdits && checkPermissions.resource.update && addTeamMembers}
      </ListGroup>
    </div>
  );
};

export default withOrganizationCheck(PointOfContacts);
