import { gql } from "@graphql/types";

export const ORGANIZATION_AUDITS = gql(`
query audits($nextToken: String, $limit: Int) {
  audits(nextToken: $nextToken, limit: $limit) {
    items {
      value: id
      label: name
      ownerGroup
    }
    nextToken
  }
}
`);
