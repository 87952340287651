import type { ReactElement } from "react";

import { modules, resources } from "@rivial-security/role-utils";
import type { UpdateVulnerabilityInput, Vulnerability } from "@rivial-security/schema-types";

import { GENERIC_FIELD_TYPES } from "../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { PriorityBadge } from "../../../../Program/PriorityActions/CustomFields/PriorityBadge/PriorityBadge";

import { getVulnerabilityPriorityDropdownData } from "./functions/getVulnerabilityPriorityDropdownData";
import { performManualPriorityUpdate } from "./functions/performManualPriorityUpdate";
import { renderManualPriorityAlert } from "./functions/renderManualPriorityAlert";

import type { CheckedVulnerability, EditedVulnerability } from "./types";

export type VulnerabilityWithPriority = Partial<Vulnerability> & { priority?: number };

export interface VulnerabilityPriorityProps {
  item?: VulnerabilityWithPriority;
  module?: string;
  resource?: string;
  updateItemById?: (item: UpdateVulnerabilityInput) => void;
  fieldContext?: string;
}
const VulnerabilityPriority = ({
  item,
  module = modules.VULNERABILITIES,
  resource = resources.VULNERABILITY,
  updateItemById,
  fieldContext,
  ...props
}: VulnerabilityPriorityProps): ReactElement => {
  const finalPriority = item?.manualPriority || item?.priority;

  if (!item?.id) {
    return <PriorityBadge priority={finalPriority} />;
  }
  const checkedItem: CheckedVulnerability = { ...item, manualPriority: finalPriority, id: item.id };

  return (
    <GenericEditFieldV3
      item={checkedItem}
      module={module}
      resource={resource}
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      inputConfig={{
        data: getVulnerabilityPriorityDropdownData({ systemPriority: item?.priority }),
      }}
      mutationFunction={(newItem: EditedVulnerability) => performManualPriorityUpdate({ newItem, updateItemById })}
      customDisplayComponent={<PriorityBadge priority={finalPriority} />}
      fieldContext={fieldContext}
      {...props}
      field={"manualPriority"}
      renderAddonElement={() => renderManualPriorityAlert({ fieldContext, vulnerability: item })}
    />
  );
};

export default VulnerabilityPriority;
