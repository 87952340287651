import React, { useEffect, useState } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useDocumentDataGrid } from "../../../../hooks/views/useDocumentGrid/hooks/useDocumentDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays documents for an exercise
 * @param {object} item - exercise
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 */
const ExerciseDocuments = ({
  item,
  organizationID,
  module = modules.INCIDENT_RESPONSE,
  resource = resources.EXERCISE,
}) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    setDocuments(item?.documents?.items);
  }, [item?.documents]);

  useEffect(() => {
    grid?.setData(documents);
  }, [documents]);

  const grid = useDocumentDataGrid({
    organizationID,
    gridHeight: "100%",
    allowPaging: false,
    query: null,
    connectionInput: { exerciseID: item?.id },
    module,
    resource,
  });

  return <div style={{ height: "30em" }}>{grid.gridDisplay}</div>;
};

export default withOrganizationCheck(ExerciseDocuments);
