import { ItemMutation } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import * as Sentry from "@sentry/react";

import type { Assessment, Target, Vulnerability } from "@rivial-security/schema-types";

import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { VULNERABILITY_SEVERITY_LEVEL } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { createTestingAssessmentVulnerabilityLink } from "../../Assessments/functions/CreateTestingAssessmentVulnerabilityLink";

import { createTargetFindingLink } from "./createTargetFindingLink";

export interface CreateVulnerabilityParams {
  vulnerability: Omit<Vulnerability, "id">;
  assessment: Assessment;
  target: Target;
  isTargetDetailsView: boolean;
}

export const createVulnerability = async ({
  vulnerability,
  assessment,
  target,
  isTargetDetailsView,
}: CreateVulnerabilityParams): Promise<Partial<Vulnerability> | null> => {
  try {
    const { createMutation } = generateGraphql("Vulnerability", [
      "name",
      "createdAt",
      "type",
      "severityLevel",
      "ports",
      "protocols",
      "cvss",
      "cves",
      "impact",
      "solutionType",
      "exploitAvailable",
      "summary",
    ]);

    let manualPriority: number;
    const lowerCaseSeverityLevel: string | undefined = vulnerability?.severityLevel?.toLowerCase();
    switch (lowerCaseSeverityLevel) {
      case VULNERABILITY_SEVERITY_LEVEL.HIGH:
        manualPriority = 400;
        break;
      case VULNERABILITY_SEVERITY_LEVEL.MEDIUM:
        manualPriority = 300;
        break;
      case VULNERABILITY_SEVERITY_LEVEL.LOW:
        manualPriority = 200;
        break;
      case VULNERABILITY_SEVERITY_LEVEL.INFO:
        manualPriority = 100;
        break;
      default:
        manualPriority = 0;
    }

    const organizationID = vulnerability?.ownerGroup;
    if (!organizationID) {
      throw new Error("Organization ID is missing when creating manual vulnerability");
    }

    const newVulnerability = await ItemMutation<Partial<Vulnerability>>({
      mutation: createMutation,
      input: {
        ...vulnerability,
        manualPriority,
      },
    });

    if (organizationID && assessment) {
      await createTestingAssessmentVulnerabilityLink({
        vulnerability: newVulnerability,
        assessment: assessment,
        organizationID,
      });
    }

    if (organizationID && target && !isTargetDetailsView) {
      await createTargetFindingLink({
        target,
        vulnerability: newVulnerability,
        organizationID,
      });
    }

    InfoLogger(`Vulnerability ${newVulnerability?.id} was Successfully Created`);
    return newVulnerability;
  } catch (e) {
    Sentry.captureException(e);
    ErrorLogger("Error in createVulnerability: ", e);
    return null;
  }
};
