/**
 * An ENUM key associated with a schema Typename. The typename much match a schema type completely
 */
const RESOURCES = {
  // ACCOUNT MANAGER
  ACCOUNT_DASHBOARD: "AccountDashboard",
  PREFERENCES: "Preferences",

  // ADMINISTRATOR
  BLUEPRINT_TEMPLATE: "BlueprintTemplate",
  CISSP_REVIEW: "CisspReview",
  CONTROL_TEMPLATE: "ControlTemplate",
  CONTROL_FRAMEWORK_TEMPLATE: "ControlFrameworkTemplate",
  EVIDENCE_TEMPLATE: "EvidenceTemplate",
  OPERATION_TEAM: "OperationTeam",
  ORGANIZATION: "Organization",
  ROLE_TEMPLATE: "RoleTemplate",
  SYSTEM_TEMPLATE: "SystemTemplate",
  RISK_CONTROL_CATEGORY_TEMPLATE: "RiskControlCategory",
  RISK_CONTROL_TEMPLATE: "RiskControl",
  KEY_RISK_INDICATOR_TEMPLATE: "KeyRiskIndicator",
  THREAT_TEMPLATE: "Threat",
  INFORMATION_ASSET_TEMPLATE: "InformationAsset",
  CUSTOM_WIDGET_TEMPLATE: "CustomWidget",
  CUSTOM_QUERY_TEMPLATE: "CustomQuery",
  EMAIL: "Email",

  // COMPLIANCE
  AUDIT: "Audit",
  CONTROL: "Control",
  CONTROL_FRAMEWORK: "ControlSet",
  DOCUMENT: "Document",
  ARTIFACT: "Artifact",
  EVIDENCE: "Evidence",
  EVIDENCE_ACTIVITY: "EvidenceActivity",
  COMPLIANCE_CONFIG: "ComplianceConfig",

  // DEVELOPER
  FEEDBACK: "Feedback",

  // HELP_CENTER
  HELP_ARTICLE: "HelpArticle",

  // INCIDENT_RESPONSE
  CLASSIFICATION_TIER: "ClassificationTier",
  DETECTION: "Detection",
  EXERCISE: "Exercise",
  INCIDENT: "Incident",
  PLAYBOOK: "Playbook",
  RESPONSE_PLAN: "IncidentResponsePlan",
  RESPONSE_TEAM: "ResponseTeam",

  // METRICS
  KEY_PERFORMANCE_INDICATOR: "KeyPerformanceIndicator",
  METRIC: "Metric",
  METRIC_TYPE: "MetricType",

  // ORGANIZATION_MANAGER
  AUTOMATION: "Automation",
  CUSTOM_QUERY: "CustomQuery",
  CUSTOM_WIDGET: "CustomWidget",
  CUSTOM_DASHBOARD: "CustomDashboard",
  DEPARTMENT: "Departments",
  QUESTIONNAIRE: "Questionnaire",
  QUESTIONNAIRE_ASSIGNEE: "QuestionnaireAssignee",
  EMAIL_TEMPLATE: "Email",
  JOB: "Job",
  LOCATION: "Location",
  POINT_OF_CONTACT: "PointOfContact",
  ROLE: "Role",
  USER: "User",
  BRANDING: "Branding",

  // OPERATION_PANEL // NOTE: THIS DOES NOT GET PUT INTO DEFAULT ROLE CONFIG
  OPERATION_TEAM_TEMPLATE: "OperationTeamTemplate",
  OPERATION_TEAM_ORGANIZATION: "OperationTeamOrganization",
  OPERATION_TEAM_USER: "OperationTeamUser",

  // GOVERNANCE
  ACTION_ITEM: "GenericActionItem",
  BLUEPRINT: "MaturityMatrix",
  IMPLEMENTATION_LEVEL: "ImplementationLevel",
  MEETING: "Meeting",
  AGENDA_ITEM: "AgendaItem",
  OBSERVATION: "Observation",
  POLICY: "Policy",
  PROGRAM_ELEMENT: "ProgramElement",
  PROGRAM_SECTION: "ProgramSection",
  RECOMMENDATION: "RecommendationItem",
  SOURCE: "Source",
  TASK: "Task",

  // REPORT
  REPORT: "Report",
  REPORT_TEMPLATE: "ReportTemplate",

  // RISK
  INFORMATION_ASSET: "InformationAsset",
  INFORMATION_SYSTEM: "System",
  KEY_RISK_INDICATOR: "Risk",
  LOSS_TOLERANCE: "LossTolerance",
  RISK_ASSESSMENT: "RiskAssessment",
  RISK_CHANGE: "RiskChange",
  RISK_CONFIG: "RiskConfig",
  RISK_CONTROL: "RiskControl",
  RISK_CONTROL_CATEGORY: "RiskControlCategory",
  THREAT: "Threat",

  // VULNERABILITIES
  ASSESSMENT: "Assessment",
  SECURITY_APPLIANCE: "SecurityAppliance",
  TARGET: "Target",
  VULNERABILITY: "Vulnerability",

  // TOOLS
  TOOL: "Tool",
  KNOW_BE_4_TRAINING: "KnowBe4Training",
  KNOW_BE_4_PHISHING: "KnowBe4Phishing",
  KNOW_BE_4_USER: "KnowBe4User",
  BLACK_KITE_COMPANY: "BlackKiteCompany",
  MITRE_ATTACK_TECHNIQUE: "MitreAttackTechnique",
  MITRE_ATTACK_MITIGATION: "MitreAttackMitigation",
  MITRE_ATTACK_TACTIC: "MitreAttackTactic",
  MITRE_ATTACK_MATRIX: "MitreAttackMatrix",
  MITRE_ATTACK_GROUP: "MitreAttackGroup",
  MITRE_ATTACK_SOFTWARE: "MitreAttackSoftware",
  MITRE_ATTACK_DATA_SOURCE: "MitreAttackDataSource",
  CUSTOM_RESOURCE_TYPE: "CustomResourceType",
  CUSTOM_RESOURCE_ENTRY: "CustomResourceEntry",

  // TRAINING

  // VENDOR
  VENDOR: "Vendor",
  VENDOR_CERTIFICATION: "VendorCertification",
  VENDOR_CONTROL: "VendorControl",
  VENDOR_CONTROL_CATEGORY: "VendorControlCategory",
  VENDOR_DOCUMENT: "VendorDocument",
  VENDOR_REVIEW: "VendorReview",
  VENDOR_SOLUTION: "VendorSolution",
};

export default RESOURCES;
