import React from "react";

import { NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

import type { NavigationModule } from "@application/Navigation/types";

const DevTools = React.lazy(() => import("@views/DevPanel/Tools/DevToolsDashboard"));

export const developerNavigation: NavigationModule = {
  moduleName: modules.DEVELOPER,
  sectionName: NavigationSectionName.MANAGEMENT,
  name: "Developer",
  path: "/developer",
  defaultRoutePath: "/tools",
  icon: IconName.CODE,
  routes: [
    {
      path: "/tools",
      name: "Tools",
      component: DevTools,
    },
  ],
};
