import { createInlineText, createText } from "@rivial-security/report-utils";

import type { TextBlock } from "@rivial-security/report-utils";

export const generateGeneralVendorReviewInformation = (vendorReview): TextBlock[] => {
  const vendorName = vendorReview?.vendorSolution?.vendor?.name || "";

  const vendorSolutionName = vendorReview?.vendorSolution?.name || "";

  const vendorReviewCreationDate = new Date(vendorReview?.createdAt);

  return [
    createText([createInlineText("Vendor: ", undefined, { bold: true }), createInlineText(vendorName)]),
    createText([
      createInlineText("Vendor Product/Service: ", undefined, { bold: true }),
      createInlineText(vendorSolutionName),
    ]),
    createText([
      createInlineText("Product/Service Common Name: ", undefined, {
        bold: true,
      }),
      createInlineText("NA"),
    ]),
    createText([
      createInlineText("Date of Testing Range: ", undefined, { bold: true }),
      createInlineText(vendorReviewCreationDate.toLocaleDateString()),
    ]),
  ];
};
