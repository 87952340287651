import type { AssessmentVulnerabilityLink, Vulnerability } from "@rivial-security/schema-types";

import { VULNERABILITY_SEVERITY_LEVEL } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { normalizeVulnerabilityData } from "../../Vulnerabilities/functions/normalizeVulnerabilityData";

export interface NormalizeAssessmentVulnerabilitiesParams {
  showInfoVulnerabilities?: boolean;
  links: Partial<AssessmentVulnerabilityLink>[];
}

export const normalizeAssessmentVulnerabilities = ({
  showInfoVulnerabilities = false,
  links,
}: NormalizeAssessmentVulnerabilitiesParams): Partial<Vulnerability>[] => {
  const normalizedArray: Partial<Vulnerability>[] = [];
  for (const link of links) {
    const vulnerability = link?.vulnerability;
    const vulnerabilityID = vulnerability?.id;
    if (!vulnerabilityID) {
      continue;
    }
    if (normalizedArray.some((addedVulnerability) => addedVulnerability.id === vulnerabilityID)) {
      continue;
    }

    if (showInfoVulnerabilities) {
      normalizedArray.push(vulnerability);
    } else if (vulnerability?.severityLevel !== VULNERABILITY_SEVERITY_LEVEL.INFO) {
      normalizedArray.push(vulnerability);
    }
  }

  return normalizeVulnerabilityData({
    vulnerabilities: normalizedArray,
    calculatePriority: true,
  });
};
