import { isNonEmptyArray } from "@rivial-security/func-utils";

import type { AutomationTrigger } from "@rivial-security/schema-types";
import { AutomationTriggerType } from "@rivial-security/schema-types";

export const getAutomationTriggerType = (automationTriggers?: AutomationTrigger[]): AutomationTriggerType => {
  let firstTrigger: AutomationTrigger | undefined;
  if (isNonEmptyArray(automationTriggers)) {
    firstTrigger = automationTriggers?.[0];
  }

  if (!firstTrigger) {
    return AutomationTriggerType.MANUAL;
  }

  return firstTrigger?.type ?? AutomationTriggerType.MANUAL;
};
