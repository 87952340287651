import type { ReactElement } from "react";

import { formattedName, isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { useState } from "react";

import type { PointOfContact } from "@rivial-security/schema-types";

import Button, { ButtonVariant } from "@components/Atoms/Button/Button";
import { useForm } from "@hooks/views/useForm";
import { FORM_INPUT_TYPES } from "@hooks/views/useForm/enums/FORM_INPUT_TYPES";
import { useModal } from "@hooks/views/useModal";
import StyledWrapper from "@utils/GenericComponents/StyledWrapper";
import PointOfContactDataGrid from "@views/OrganizationManager/PointOfContacts/components/PointOfContactDataGrid";
import PointOfContactName from "@views/OrganizationManager/PointOfContacts/hooks/useSelectPointOfContact/components/PointOfContactName";

import type { UseModalReturnType } from "@hooks/views/useModal";

export interface UseSelectPointOfContactParams<T> {
  item?: T;
  connectionField: string;
  connectionIDField: string;
  nameField?: string;
  additionalFormSteps?: Record<string, unknown>[];
  onSubmit?: ({ formInput, pointOfContact }) => Promise<void>;
  afterSubmit?: ({ pointOfContact }) => Promise<void>;
  handleUnassign?: ({ pointOfContact }) => Promise<void>;
  isDisabled: boolean;
  organizationID: string;
  resetFunction?: () => void;
}

export const useSelectPointOfContact = <T,>({
  item,
  connectionField = "pointOfContact",
  nameField = "name",
  additionalFormSteps,
  onSubmit,
  afterSubmit,
  handleUnassign,
  isDisabled,
  organizationID,
  resetFunction,
}: UseSelectPointOfContactParams<T>): UseModalReturnType => {
  const selectPointOfContactStepID = "select-point-of-contact";
  const [pointOfContact, setPointOfContact] = useState(item?.[connectionField]);
  const onPointOfContactSelected = async (selectedPointOfContact: Partial<PointOfContact>): Promise<void> => {
    setPointOfContact(selectedPointOfContact);
    form.setInput((input) => ({ ...input, pointOfContactID: selectedPointOfContact.id }));

    if (isNonEmptyArray(additionalFormSteps)) {
      form?.stepper?.setSteps((steps) => {
        const selectPointOfContactStep = steps.find((step) => step.id === selectPointOfContactStepID);
        selectPointOfContactStep.text = `Selected ${formattedName({ pointOfContact: selectedPointOfContact })}`;
        return steps;
      });
    }

    if (!isNonEmptyArray(additionalFormSteps)) {
      modal.setModalIsOpen(false);
      await onSubmit?.({ pointOfContact: selectedPointOfContact, formInput: undefined });
      if (afterSubmit) {
        await afterSubmit({ pointOfContact: selectedPointOfContact });
        resetFunction?.();
      }
    } else {
      form?.stepper?.handleNext();
    }
  };

  const onPointOfContactUnassignPress = async () => {
    modal.setModalIsOpen(false);
    await onSubmit?.({ pointOfContact: undefined, formInput: undefined });
    await handleUnassign?.({ pointOfContact });
  };

  const fieldConfig = {
    pointOfContactID: {
      inputType: FORM_INPUT_TYPES.CUSTOM,
      required: true,
      customConfig: {
        component: (
          <StyledWrapper wrapperStyle={{ height: "70vh" }}>
            <PointOfContactDataGrid enableSelectButton={true} onSelectCallback={onPointOfContactSelected} />
          </StyledWrapper>
        ),
      },
    },
  };

  let formSteps: Record<string, unknown> | Record<string, unknown>[] = fieldConfig;
  if (isNonEmptyArray(additionalFormSteps)) {
    formSteps = [
      {
        id: selectPointOfContactStepID,
        stepName: "Select Point Of Contact",
        fields: fieldConfig,
      },
      ...(additionalFormSteps ?? []),
    ];
  }

  const form = useForm({
    fieldConfig: formSteps,
    footerPrefix: !isNullOrUndefined(item?.[connectionField]) ? (
      <UnassignButton item={item} connectionField={connectionField} onClick={onPointOfContactUnassignPress} />
    ) : undefined,
    organizationID,
    disableResetButton: !Array.isArray(formSteps),
    disableSubmitButton: !Array.isArray(formSteps),
    submitFunction: async (input) => {
      modal.setModalIsOpen(false);
      await onSubmit?.({ pointOfContact, formInput: input });
      await afterSubmit?.({ pointOfContact });
    },
  });

  const modal = useModal(
    <ModalHeader item={item} nameField={nameField} />,
    <div>{form.display}</div>,
    <PointOfContactName pointOfContact={item?.[connectionField]} isDisabled={isDisabled} />,
    {
      width: "75vw",
      isDisabled,
      onClosed: () => {
        form?.stepper?.handleReset?.();
      },
    },
  );

  return modal;
};

interface ModalHeaderProps<T> {
  item?: T;
  nameField: string;
}

const ModalHeader = <T,>({ item, nameField }: ModalHeaderProps<T>): ReactElement => {
  const itemName: string = (item?.[nameField] as string) || "Resource";
  return (
    <span>
      Select a Point Of Contact for: <strong>{itemName}</strong>
    </span>
  );
};

interface UnassignButtonProps<T> {
  item?: T;
  connectionField: string;
  onClick: VoidFunction;
}

const UnassignButton = <T,>({ item, connectionField, onClick }: UnassignButtonProps<T>): ReactElement => {
  const pointOfContactName = formattedName({
    pointOfContact: item?.[connectionField],
  });

  return (
    <Button variant={ButtonVariant.ALERT} onClick={onClick}>
      Only Unassign {pointOfContactName}
    </Button>
  );
};
