import type { CSSProperties } from "react";

import { Button } from "reactstrap";

import DashedLineBorderOverlay from "../Overlays/DashedLineBorderOverlay";

export interface CallToAction {
  message?: string;
  function?: () => void;
  placeOnNewLine?: boolean;
  size?: string;
}

export interface NotEnoughDataProps {
  message?: string;
  itemName?: string;
  callToAction?: CallToAction;
  dashedOverlayProps?: Record<string, unknown>;
  sx?: {
    button: CSSProperties;
  };
}

/**
 * Displays a message telling the user that there is not enough data to render the item
 * @param message - a message to display to the user (what kind of data is missing)
 * @param itemName - the component or entity that doesn't have enough data (for use in tests)
 * @param callToAction - object containing input for clickable call to action when there is not enough data
 * @param callToAction.message - message that tells the user what to do so the component gets enough data
 * @param callToAction.function - if present will show a "Click here" link at the start of callToAction.message that will call the function onClick
 * @param dashedOverlayProps - props to pass to the DashedLineBorderOverlay component
 * @param sx - css inline styles to apply to different parts of the message (available keys: button)
 */
const NotEnoughData = ({
  message = "There is not enough data to render this component",
  callToAction,
  dashedOverlayProps = {},
  itemName = "",
  sx,
}: NotEnoughDataProps): JSX.Element => {
  const onClickCallToAction = (): void => {
    if (callToAction?.function) {
      callToAction.function();
    }
  };

  const validCTAFunction = callToAction?.function && typeof callToAction.function === "function";

  const itemNameSuffix = itemName === "" ? "" : `-${itemName}`;

  return (
    <span data-testid={`not-enough-data${itemNameSuffix}`}>
      <DashedLineBorderOverlay enabled={false} {...dashedOverlayProps}>
        <>
          <span>{`${message} `}</span>
          {callToAction?.placeOnNewLine && <br data-testid={"not-enough-data-br"} />}
          {validCTAFunction && (
            <Button
              size={callToAction?.size ?? ""}
              id={`call-to-action-button${itemNameSuffix}`}
              data-testid={`call-to-action-button${itemNameSuffix}`}
              style={{
                paddingRight: "0",
                paddingTop: ".2em",
                marginLeft: "-0.5em",
                ...sx?.button,
              }}
              color={"link"}
              onClick={onClickCallToAction}
            >
              Click Here
            </Button>
          )}
          <span>{callToAction?.message || (validCTAFunction && " to fix this.") || ""}</span>
        </>
      </DashedLineBorderOverlay>
    </span>
  );
};

export default NotEnoughData;
