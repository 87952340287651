import type { ReactElement } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import type { Vulnerability } from "@rivial-security/schema-types";

import Alert, { AlertVariant } from "@components/Atoms/Alert/Alert";

import { fieldContexts } from "../../../../../../enums/fieldContexts";

export interface RenderManualPriorityAlertParams {
  vulnerability?: Partial<Vulnerability>;
  fieldContext?: string;
}

export const renderManualPriorityAlert = ({
  fieldContext,
  vulnerability,
}: RenderManualPriorityAlertParams): ReactElement | undefined => {
  if (fieldContext === fieldContexts.DETAILS && !isNullOrUndefined(vulnerability?.manualPriority)) {
    return <Alert variant={AlertVariant.INFO} content={"You have manually overridden the system-assigned priority."} />;
  }
  return undefined;
};
