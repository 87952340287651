import { concatNotes } from "@rivial-security/func-utils";
import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { useDataGrid } from "../../../../../../../hooks/views/useDataGrid/useDataGrid";
import StyledWrapper from "../../../../../../../utils/GenericComponents/StyledWrapper";
import SubControlNotes from "../../../../../Systems/components/SystemAccordion/ControlCategories/components/Controls/components/SubControlNotes";
import RiskControlDetails from "../components/RiskControlDetails";
import ImplementationRating from "../customFields/ImplementationRating";
import KeyControl from "../customFields/KeyControl";
import StrengthRating from "../customFields/StrengthRating";
import { deleteRiskControl } from "../functions/deleteRiskControl";

/**
 * Displays enterprise risk controls for the org
 * @param {string} controlCategoryId - the id of the control category
 * @param {string} organizationID - ownerGroup ID of the Organization, Template, or OperationTeam
 * @param {object} queryConfig - configuration overrides for the grid query
 * @param {object} gridConfig - configuration overrides for the grid UI
 * @param {object} cardConfig - configuration overrides for the card UI
 */
export const useRiskControlDataGrid = ({
  organizationID,
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  ...props
}) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL;
  const typename = "RiskControl";
  const route = "#/risk/risk_config/risk_controls/";

  const fields = [
    {
      name: "statementNumber",
      description: "A unique identifier for this Control",
      width: 150,
      sortComparator: (a, b) => {
        return a?.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      },
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "name",
      description: "Summary of the Control",
      flex: 1,
      minWidth: 300,
      bulkEdit: true,
    },
    {
      name: "implementationRating",
      description: "Percentage representing how well this control is implemented across the whole organization.",
      component: <ImplementationRating disableTooltip={true} />,
      width: 175,
      bulkEdit: true,
    },
    {
      name: "strengthRating",
      description: "Maximum percentage that this control will reduce the likelihood of a risk occurring.",
      component: <StrengthRating disableTooltip={true} />,
      width: 175,
      bulkEdit: true,
    },
    {
      name: "keyControl",
      description: "Indicates whether this control is a key control.",
      component: <KeyControl />,
      width: 100,
      disablePropagation: true,
      type: "boolean",
      bulkEdit: true,
    },
    {
      name: "notes",
      description: "Notes about this Control at the Organization level.",
      component: (
        <StyledWrapper wrapperStyle={{ width: "100%" }}>
          <SubControlNotes disableTitle={true} />
        </StyledWrapper>
      ),
      width: 300,
      valueFormatter: (value) => {
        return Array.isArray(value) ? concatNotes(value) : value;
      },
    },
  ];

  const sortSettings = {
    columns: [{ field: "statementNumber", direction: "Ascending" }],
  };

  cardConfig = {
    title: "Risk Controls",
    ...cardConfig,
  };

  const { updateMutation } = generateGraphql("RiskControl", [
    "name",
    "statementNumber",
    "implementationRating",
    "strengthRating",
    "keyControl",
  ]);

  queryConfig = {
    query: realTimeRiskGQL.listRiskControls_riskControlList,
    organizationID: organizationID,
    ...queryConfig,
  };

  gridConfig = {
    route,
    typename,
    module,
    resource,
    fields,
    disableVirtualization: true,
    enablePersistence: false, // persistence is disabled
    sortSettings,
    detailsComponent: <RiskControlDetails />,
    enableSearch: true,
    enableContextMenu: true,
    deleteFunction: deleteRiskControl,
    updateMutation,
    options: ["details", "delete", "edit"],
    persistenceUUID: "a11195e9-7976-4f93-90a1-976eff71d264",
    ...gridConfig,
    ...props,
  };

  queryConfig = {
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const queryCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...roleConfig,
    ...cardConfig,
  });

  return { ...queryCard };
};
