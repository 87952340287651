import { GetQuery } from "@rivial-security/appsync-utils";
import { concatNotes } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import type { VendorReview } from "@rivial-security/schema-types";

import { getCategoryCompletion } from "./getCategoryCompletion";
import { getTextSymbol } from "./getTextSymbol";

/**
 * @description Get vendor review report data
 * @param {object} input - params to get report data
 * @returns {object} {Promise<*&{reportType: string, summary: *, controlCategories: *[], vendorReview: any[], rating: *}>}
 */
export const getVendorReviewReportData = async ({ input }) => {
  const { itemId, vendorReviewID } = input?.config ?? null;

  if (!itemId && !vendorReviewID) throw Error("Missing itemId");

  const reportType = "Vendor Cybersecurity Assessment";

  const nestedFields = {
    vendorSolution: `
      {
        name
        vendor {
          name
        }
      }
    `,
    controlCategories: `(limit: 100)
      {
        items {
          name
          subControls (limit: 100) {
            items {
              inPlace
              audited
            }
          }
          notes {
            id
            type
            ownerGroup
            author
            timeStamp
            content
            tag
            observationID
          }
        }
      }
    `,
  };

  const { getQuery } = generateGraphql(
    "VendorReview",
    ["name", "rating", "createdAt", "vendorSolution", "controlCategories", "summary"],
    nestedFields,
  );

  const vendorReview = await GetQuery<VendorReview>({
    query: getQuery,
    variables: {
      id: itemId || vendorReviewID,
    },
  });

  //Generate formatted control category items
  const formattedControlCategoryItems: {
    name: string;
    inPlace: string;
    audited: string;
    notes: string;
    details: string;
  }[] = [];

  const controlCategoryItems = vendorReview?.controlCategories?.items;
  if (Array.isArray(controlCategoryItems)) {
    for (const category of controlCategoryItems) {
      const inPlace = getCategoryCompletion({ property: "inPlace", category });
      const audited = getCategoryCompletion({ property: "audited", category });

      formattedControlCategoryItems.push({
        name: category?.name || "Control Category",
        inPlace: getTextSymbol(inPlace),
        audited: getTextSymbol(audited),
        notes: concatNotes(category?.notes || []),
        details: "",
      });
    }
  }

  //Set up the report config
  return {
    ...input,
    reportType,
    vendorReview,
    controlCategories: formattedControlCategoryItems,
    rating: vendorReview?.rating,
    summary: vendorReview?.summary,
  };
};
