import type { ComponentPropsWithRef } from "react";

import cx from "classnames";
import { forwardRef } from "react";

import { getIcon } from "@components/Ions/icons/components/Icons";

import StylelessButton from "../StylelessButton/StylelessButton";

import styles from "./IconButton.module.scss";

import type { IconName } from "@components/Ions/icons/components/Icons";

export enum IconButtonVariant {
  LIGHT = "light",
  DARK = "dark",
}

export interface IconButtonProps extends ComponentPropsWithRef<"button"> {
  /** The variant for the button depending on the background it's placed on (`light` or `dark`). */
  variant?: IconButtonVariant;
  /** Which icon should be displayed in the button. */
  iconName: IconName;
}

/**
 * A button that only has an icon.  This differs from a normal `Button` component since it's not used as a call to action.
 */
const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ variant = IconButtonVariant.LIGHT, iconName, className, ...props }, ref) => {
    const classNames = cx(styles["icon-button"], styles[`icon-button--${variant}`], className);

    return (
      <StylelessButton ref={ref} className={classNames} {...props}>
        {getIcon(iconName)}
      </StylelessButton>
    );
  },
);

IconButton.displayName = "IconButton";

export default IconButton;
