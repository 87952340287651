import { useEffect, useState } from "react";

import ListView from "../../../../../utils/GenericComponents/Charts/ListView";

import type { BlackKiteResponse } from "../components/VendorBlackKiteCompany";

/**
 * @description Black Kite Ecosystems component
 */
const BlackKiteEcosystems = ({ Ecosystems }: BlackKiteResponse): JSX.Element => {
  const [list, setList] = useState<{ id: number; text: string }[]>([]);

  useEffect(() => {
    if (Array.isArray(Ecosystems) && Ecosystems.length > 0) {
      const tempList = Ecosystems.map((system, index) => {
        return {
          id: index,
          text: system?.EcosystemName,
        };
      });

      setList(tempList);
    }
  }, [Ecosystems]);

  return <ListView list={list} />;
};

export default BlackKiteEcosystems;
