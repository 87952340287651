import type { CSSProperties, ReactElement } from "react";

import { useContext } from "react";
import { Spinner } from "reactstrap";

import pingPongLoader from "../../assets/gif/pongLogo.gif";
import rivialLoader from "../../assets/gif/rivial_loader.gif";
import { UIContext } from "../Context/UIContext";

export interface LoaderProps {
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  text?: string;
  dataTestId?: string;
}
/**
 * Utility component for handling different loaders
 * @param style - Style object for the loader
 * @param containerStyle - style overrides object for entire loader container
 * @param text - text to add after the loader
 * @param dataTestId - custom data test id to use
 * @returns {JSX.Element}
 */
const Loader = ({ style = {}, containerStyle = {}, text, dataTestId }: LoaderProps): ReactElement => {
  const uiContext = useContext(UIContext);

  const loader = (): ReactElement => {
    switch (uiContext.loaderStyle) {
      case "pingPongLoader":
        return (
          <img
            data-testid={"custom-loader"}
            id={"custom-loader"}
            style={{ width: "32px", height: "32px", ...style }}
            src={pingPongLoader}
            alt="loading.."
          />
        );
      case "rivialLoader":
        return (
          <img
            data-testid={"custom-loader"}
            id={"custom-loader"}
            style={{ width: "32px", height: "32px", ...style }}
            src={rivialLoader}
            alt="loading.."
          />
        );
      case "spinnerLoader":
      default:
        return (
          <Spinner data-testid={"custom-loader"} id={"custom-loader"} style={{ ...style }} size="sm" color="primary" />
        );
    }
  };

  return (
    <div
      data-testid={dataTestId ?? "generic-loader"}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        ...containerStyle,
      }}
    >
      <div style={{ minWidth: "2em" }}>{loader()}</div>
      {typeof text === "string" && text.length > 0 && <div style={{ marginLeft: ".5em" }}>{text}</div>}
    </div>
  );
};

export default Loader;
