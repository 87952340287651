import { TOOL_TYPES } from "@rivial-security/integration-utils";

import StyledWrapper from "@utils/GenericComponents/StyledWrapper";

import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import CheckTool from "../../../Tool/components/CheckTool";
import { useKnowBe4PhishingDataGrid } from "../hooks/useKnowBe4PhishingDataGrid";

/**
 * @description Display list of KnowBe4 Phishing Tests component
 * @param {object} props
 * @constructor
 */
const KnowBe4PhishingDataGrid = (props) => {
  const toolType = TOOL_TYPES.KNOW_BE_4;
  return useKnowBe4PhishingDataGrid({ toolType, ...props }).dashboardDisplay;
};

const KnowBe4PhishingDataGridCheck = (props) => {
  const toolType = TOOL_TYPES.KNOW_BE_4;

  return (
    <CheckTool toolType={toolType} organizationID={props?.organizationID}>
      <StyledWrapper wrapperStyle={{ height: "30em" }}>
        <KnowBe4PhishingDataGrid {...props} />
      </StyledWrapper>
    </CheckTool>
  );
};

export default withOrganizationCheck(KnowBe4PhishingDataGridCheck);
