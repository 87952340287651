import type { ReactElement } from "react";

export interface ArrayCustomFieldProps {
  item: Record<string, unknown>;
  fieldName: string;
}

const ArrayCustomField = ({ item, fieldName }: ArrayCustomFieldProps): ReactElement => {
  const values = item[fieldName] as string[] | undefined;
  return <div>{values?.join(", ")}</div>;
};

export default ArrayCustomField;
