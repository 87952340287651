import log from "loglevel";

import type { LogLevelNames } from "loglevel";

const createLogger = (level: LogLevelNames) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (message: string, ...msg: any[]): void => {
    if (msg.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      log[level](message, ...msg);
    } else {
      log[level](message);
    }
  };
};

/**
 * @description Logs an error message.  Errors are the most severe form of log message, usually reserved when nothing can be done by the user to remedy the issue.
 *
 * Proper error messages should:
 *
 * 1. Not start with a word like "error" but just contain the message
 * 2. Should not contain info about where the log came from, this is captured by the trace
 * 3. Not end in an ellipses for brevity
 * 4. Not include objects in the first parameter
 * 5. Include the error as the second param (Wrong: `ErrorLogger(`My error ${JSON.stringify(e)}`);`)
 *
 * @param message - the main message for the log
 * @param msg - additional information like the error, variables, or an object
 */
export const ErrorLogger = createLogger("error");

/**
 * @description Logs an warning message.  Warnings can be used to help guide the user about an invalid action or incorrect input.
 *
 * Proper warning messages should:
 *
 * 1. Not start with a word like "warning" but just contain the message
 * 2. Should not contain info about where the log came from, this is captured by the trace
 * 3. Not end in an ellipses for brevity
 * 4. Not include objects in the first parameter
 *
 * @param message - the main message for the log
 * @param msg - additional information like variables, or an object
 */
export const WarningLogger = createLogger("warn");

/**
 * @description Logs an info message.  Info message contain extra information to assist in understand a flow through the application.  They are usually not visible in production.
 *
 * Proper info messages should:
 *
 * 1. Not start with a word like "info" but just contain the message
 * 2. Not end in an ellipses for brevity
 * 3. Not include objects in the first parameter
 *
 * @param message - the main message for the log
 * @param msg - additional information like variables, or an object
 */
export const InfoLogger = createLogger("info");
