import { ItemMutation } from "@rivial-security/appsync-utils";

import type { CreateJobInput, Job } from "@rivial-security/schema-types";

import type { OptionalNullable } from "../../../../typedefs/Utility/mutations";

export const createJob = async ({
  name,
  completedAt,
  status,
  type,
  logs,
  error,
  success,
  emails = [],
  source,
  ownerGroup,
  ...props
}: CreateJobInput): Promise<OptionalNullable<Job> | null> => {
  return (await ItemMutation({
    mutation: createJobMutation,
    input: {
      name,
      completedAt,
      status,
      type,
      logs,
      error,
      success,
      emails,
      source,
      ownerGroup,
      ...props,
    },
  })) as OptionalNullable<Job>;
};

const createJobMutation = /* GraphQL */ `
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      id
      name
      completedAt
      status
      type
      logs
      error
      success
      emails
      source
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;
