import type { ReactElement } from "react";

import IconChevron from "../../../../assets/svg/input/icon-chevron.svg";
import styles from "../Icons.module.scss";

export const IconChevronUp = (): ReactElement => {
  return <IconChevron />;
};

export const IconChevronRight = (): ReactElement => {
  return <IconChevron className={styles.right} />;
};

export const IconChevronDown = (): ReactElement => {
  return <IconChevron className={styles.down} />;
};

export const IconChevronLeft = (): ReactElement => {
  return <IconChevron className={styles.left} />;
};
