import type { ReactElement } from "react";

import { GetQuery } from "@rivial-security/appsync-utils";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useState } from "react";

import type { Vulnerability } from "@rivial-security/schema-types";

import Alert, { AlertVariant } from "@components/Atoms/Alert/Alert";
import { useSetAsyncData } from "@hooks/functional/useSetAsyncData";
import { useDataGrid } from "@hooks/views/useDataGrid/useDataGrid";

import styles from "./VulnerabilityRawDataDataGrid.module.scss";

export interface VulnerabilityRawDataSpreadsheetProps {
  vulnerabilityID: string;
}

const VulnerabilityRawDataDataGrid = ({ vulnerabilityID }: VulnerabilityRawDataSpreadsheetProps): ReactElement => {
  const [hasNoData, setHasNoData] = useState(true);

  const fields = [
    { name: "ip", friendlyName: "IP Address", flex: 1 },
    { name: "port", flex: 1 },
    { name: "protocol", flex: 1 },
    { name: "cve", friendlyName: "CVE", flex: 1 },
  ];

  const { resetFunction, isLoading } = useSetAsyncData({
    getData: async () => {
      const { getQuery } = generateGraphql("Vulnerability", ["rawData"], {
        rawData: `{
          ip
          port
          protocol
          cve
        }`,
      });

      return await GetQuery({
        query: getQuery,
        variables: { id: vulnerabilityID },
      });
    },
    setData: (vulnerability: Partial<Vulnerability>) => {
      let rawData = vulnerability?.rawData;
      if (!isNonEmptyArray(rawData)) {
        rawData = [];
        setHasNoData(true);
      } else {
        setHasNoData(false);
      }

      grid.setData(rawData);
    },
  });

  const grid = useDataGrid({
    query: null,
    fields,
    isLoading,
    resetFunction,
    persistenceID: "df496c32-ed77-454e-9023-b3740d7cd468",
  });

  return (
    <div className={styles["raw-data-container"]} style={{ height: "70vh" }}>
      {!isLoading && hasNoData && (
        <Alert
          className={styles["raw-data-container__no-data-alert"]}
          variant={AlertVariant.INFO}
          content={"No raw data available for this vulnerability."}
        />
      )}
      <div className={styles["raw-data-container__data-grid"]}>{grid.display}</div>
    </div>
  );
};

export default VulnerabilityRawDataDataGrid;
