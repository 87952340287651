import { completionType } from "./completionType";

/**
 * Generates a text character based on completion enum inside of completion object
 * @param {object} completion - object with status property that stores completionType enum
 * @return {string} - "X" if status is unknown or NONE, "-" if status if Partially, "✓" if status is FULLY
 */
export const getTextSymbol = (completion) => {
  //determine symbol based of completion amount
  let symbol = "X";
  if (completion?.status) {
    if (completion.status === completionType.FULLY) {
      symbol = "✓";
    } else if (completion.status === completionType.PARTIALLY) {
      symbol = "-";
    }
  }

  return symbol;
};
