import React from "react";

import { noPermissionMessage } from "../../Overlays/PermissionsOverlay";
import RequestPermissionButton from "../../Permissions/components/RequestPermissionButton";

/**
 * @description DontHavePermission Component displayed when a user doesn't have permission to a specific resource
 * @param {object} props - The props
 * @param {string} props.resource - The resource that the user doesn't have permission to
 * @param {string} props.module - The module that the resource is in
 * @param {string} [props.fontSize] - The font size of the text
 * @param {string} [props.customText] - Custom text to display
 */
export const DontHavePermission = ({ resource, fontSize = "1rem", customText, module }) => {
  return (
    <div className="e-card">
      <div className="e-card-header">
        <div className="e-card-header-caption">
          <div className="e-card-title"></div>
        </div>
      </div>
      <div className="e-card-content" style={{ fontSize: fontSize || undefined }}>
        <h5>
          {customText || noPermissionMessage({ module, resource, operationType: "view" })}
          <RequestPermissionButton module={module} resource={resource} />
        </h5>
      </div>
    </div>
  );
};
