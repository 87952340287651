import { createBlankLine, createInlineText, createTable, createText } from "@rivial-security/report-utils";

import type { TextBlock } from "@rivial-security/report-utils";

export const generateControlCategoriesTable = (controlCategories) => {
  // Generate custom text for custom fields of the table

  // same sorting algorithm that's used in fargate > reports > VendorReviewControlsReport > functions > generateControlCategoryTable.js
  const sortedCategories = controlCategories.sort((a, b) => parseInt(a.name) - parseInt(b.name));

  const getText = (field: string): TextBlock => {
    switch (field) {
      case "X":
        return createText("X", "Normal", {
          fontSize: 14,
          fontColor: "#FF0000FF",
          bold: true,
          fontFamily: "Segoe UI Symbol",
          textAlignment: "Center",
        });
      case "✓":
        return createText("✓", "Normal", {
          fontSize: 14,
          fontColor: "#008000FF",
          bold: true,
          fontFamily: "Segoe UI Symbol",
          textAlignment: "Center",
        });
      case "-":
        return createText("-", "Normal", {
          fontSize: 14,
          fontColor: "#F79646FF",
          bold: true,
          fontFamily: "Segoe UI Symbol",
          textAlignment: "Center",
        });
      default:
        throw new Error(`Invalid field: ${field}`);
    }
  };

  //Generate table components
  return [
    //Table
    createTable({
      // @ts-expect-error fields is not properly typed
      fields: ["name", "inPlace", "audited", "notes", "details"],
      fieldNameDictionary: {
        name: "Control Category",
        inPlace: "In Place",
        audited: "Audited",
        notes: "Notes",
        details: "Details",
      },
      items: sortedCategories,
      customFields: [
        // @ts-expect-error customFields is not properly typed
        {
          field: "inPlace",
          format: "sfdt",
          function: ({ inPlace }) => getText(inPlace),
        },
        // @ts-expect-error customFields is not properly typed

        {
          field: "audited",
          format: "sfdt",
          function: ({ audited }) => getText(audited),
        },
      ],
      // @ts-expect-error headerConfig is not properly typed
      headerConfig: {
        textAlignment: "Center",
        bold: true,
      },
    }),
    createBlankLine(),

    //Legend
    createText("Legend", undefined, { underline: true }),
    createText([
      createInlineText("✓", undefined, {
        fontSize: 18,
        fontColor: "#008000FF",
        bold: true,
        fontFamily: "Segoe UI Symbol",
      }),
      createInlineText("     in place / audited"),
    ]),
    createText([
      createInlineText("X", undefined, {
        fontSize: 18,
        fontColor: "#FF0000FF",
        bold: true,
        fontFamily: "Segoe UI Symbol",
      }),
      createInlineText("     not in place / not audited"),
    ]),
    createText([
      createInlineText("-", undefined, {
        fontSize: 18,
        fontColor: "#F79646FF",
        bold: true,
        fontFamily: "Segoe UI Symbol",
      }),
      createInlineText("      partially in place"),
    ]),
  ];
};
