import {
  createBlankLine,
  createInlineText,
  createPageBreak,
  createText,
  generateCoverPage,
  generateReport,
  generateSection,
} from "@rivial-security/report-utils";

import { createVendorReviewReportConfig } from "../../../Vendor/VendorSolutionReviews/functions/createVendorReviewReportConfig";

import { generateControlCategoriesTable } from "./generateControlCategoriesTable";
import { generateGeneralVendorReviewInformation } from "./generateGeneralVendorReviewInformation";
import { generateOverallRiskStatement } from "./generateOverallRiskStatement";
import { generateTypeOfReport } from "./generateTypeOfReport";
import { generateRiskAssessmentDetails } from "./genereateRiskAssessmentDetails";

export const generateVendorReviewReport = async (reportContext) => {
  const reportType = "Vendor Cybersecurity Assessment";

  // Fill out the vendor review configuration with additional data
  if (reportContext?.config?.itemId) {
    reportContext.config = await createVendorReviewReportConfig({
      ...reportContext.config,
    });
  }

  const vendorReview = reportContext?.config?.vendorReview;
  const headingStyle = {
    italic: true,
    bold: true,
    fontSize: 14,
    fontColor: "#2c4c93FF",
  };

  return generateReport([
    generateCoverPage({
      reportTitle: reportType,
      organizationName: reportContext?.organizationName || "Bank Name",
      reportTitleFontSize: 35,
    }),

    generateSection({
      blocks: [
        // Table of contents page
        // ...generateTableOfContents(),
        // createPageBreak(),

        // Audit report information part
        createText("Audit Report Information", "Heading 2", headingStyle),
        // - general info
        ...generateGeneralVendorReviewInformation(vendorReview),
        createBlankLine(),
        // - type of report info
        ...generateTypeOfReport(),
        createBlankLine(),

        // Summary part
        // - text description
        createText("Summary", "Heading 2", headingStyle),
        createText(reportContext?.config?.summary || ""),
        createBlankLine(),
        // - final statement
        ...generateOverallRiskStatement(vendorReview?.rating),
        createPageBreak(),

        // Security control categories reviewed page
        createText("Security Control Categories Reviewed", "Heading 2", headingStyle),
        ...generateControlCategoriesTable(reportContext?.config?.controlCategories),
        createPageBreak(),
        // Risk assessment details part
        createText("Risk Assessment Details", "Heading 2", headingStyle),
        ...generateRiskAssessmentDetails(vendorReview),
        createPageBreak(),

        // FI User entity Controls part
        createText("Financial Institution User Entity Controls", "Heading 2", headingStyle),
        createText(
          "(This is a list of internal controls the financial institution should consider for achievement of " +
            "control objectives identified in the SSAE16 report. These controls should be provided to the System Owner and " +
            "included in the financial institution’s internal IT Risk Assessment)",
        ),
        createBlankLine(),
        //TODO: create user entity controls?
        createText([
          createInlineText("For additional details regarding this vendor cybersecurity assessment, please contact "),
          createInlineText("info@rivialsecurity.com"), //TODO: make into a link if possible
          createInlineText("."),
        ]),
      ],
      reportType,
      reportContext,
    }),
  ]);
};
