import Skeleton from "@mui/material/Skeleton";
import { isNonEmptyArray } from "@rivial-security/func-utils";

import type { GridColDef } from "@mui/x-data-grid-pro";

export interface ProcessLoadingFieldsInput {
  dataGridColumns: GridColDef[];
  loadingFields: string[];
}

/**
 * When fields are marked as loading display component should be a loader (modifies field in place)
 */
export const processLoadingFields = ({ dataGridColumns, loadingFields }: ProcessLoadingFieldsInput): void => {
  if (!isNonEmptyArray(dataGridColumns) || !isNonEmptyArray(loadingFields)) {
    return;
  }

  for (const fieldSettings of dataGridColumns) {
    const fieldName = fieldSettings?.field;
    if (!loadingFields.includes(fieldName)) {
      continue;
    }

    fieldSettings.renderCell = () => {
      return <Skeleton variant="rectangular" width={"100%"} height={20} />;
    };
  }
};
