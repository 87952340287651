import React from "react";

import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import KnowBe4PhishingDataGrid from "../../../../../Tools/KnowBe4/Phishing/components/KnowBe4PhishingDataGrid";

/**
 * @description Allows the user to select a phishing test to upload for Evidence
 * @param {function} onSubmit - function to handle selected phishing test
 * @returns {JSX.Element}
 * @constructor
 */
const PhishingEvidenceForm = ({ onSubmit }) => {
  const cardConfig = {
    title: "Select a Phishing Test",
    enableSticky: false,
  };

  const gridConfig = {
    selectionSettings: {
      type: "Single",
    },
    enableSelectButton: true,
    onSelectCallback: (data) => {
      onSubmit?.({
        type: EVIDENCE_ACTIVITY_TYPES.PHISHING,
        phishing: {
          id: data?.pst_id,
        },
      });
    },
  };

  return <KnowBe4PhishingDataGrid cardConfig={cardConfig} gridConfig={gridConfig} />;
};

export default PhishingEvidenceForm;
