import { Redirect } from "react-router-dom";

import DynamicRedirect from "@application/Routes/components/DynamicRedirect";

import type { Route } from "@application/Layout/hooks/types/Navigation";
import type { NavigationModule, NavigationRoute } from "@application/Navigation/types";

export interface GenerateDeprecatedPathRedirectsParams {
  newRoutes: Route[];
  navigationModule: NavigationModule;
  route: NavigationRoute;
}

export const generateDeprecatedPathRedirects = ({
  newRoutes,
  navigationModule,
  route,
}: GenerateDeprecatedPathRedirectsParams): void => {
  const modulePath = navigationModule.path;
  const routePath = route.path;
  const fullPath = `${modulePath}${routePath}`;
  const routeEndsInID = routePath.endsWith(":id");

  const allRoutePaths = [routePath];
  if (route.deprecatedPaths) {
    const routeDeprecatedPaths = route.deprecatedPaths;
    allRoutePaths.push(...routeDeprecatedPaths);
    for (const deprecatedPath of routeDeprecatedPaths) {
      const deprecatedFullPath = `${modulePath}${deprecatedPath}`;
      newRoutes.push({
        name: route.name,
        path: deprecatedFullPath,
        exact: true,
        component: () => (routeEndsInID ? <DynamicRedirect to={fullPath} /> : <Redirect to={fullPath} />),
      });
    }
  }

  if (navigationModule.deprecatedPaths) {
    const deprecatedModulePaths = navigationModule.deprecatedPaths;
    for (const deprecatedModulePath of deprecatedModulePaths) {
      for (const deprecatedRoutePath of allRoutePaths) {
        const deprecatedFullPath = `${deprecatedModulePath}${deprecatedRoutePath}`;
        newRoutes.push({
          name: route.name,
          path: deprecatedFullPath,
          exact: true,
          component: () => (routeEndsInID ? <DynamicRedirect to={fullPath} /> : <Redirect to={fullPath} />),
        });
      }
    }
  }
};
