import type { ReactElement } from "react";

import { useContext } from "react";

import { WorkflowContext } from "@hooks/views/useWorkflow";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";
import { useVendorReviewDocumentsDataGrid } from "@views/Vendor/VendorSolutionReviews/hooks/useVendorReviewDocumentsDataGrid";

export interface UploadRelevantDocumentsProps {
  organizationID: string;
  disableRoleChecking?: boolean;
}

interface WorkflowContextData {
  vendorReview?: {
    id: string;
  };
  readOnly?: boolean;
}

const UploadRelevantDocuments = ({
  organizationID,
  disableRoleChecking,
}: UploadRelevantDocumentsProps): ReactElement => {
  const context: WorkflowContextData | undefined = useContext(WorkflowContext) as WorkflowContextData | undefined;
  const vendorReviewID: string | undefined = context?.vendorReview?.id;
  const disableEdits: boolean | undefined = context?.readOnly;

  const useVendorReviewDocumentsHook = useVendorReviewDocumentsDataGrid({
    organizationID,
    vendorReviewID,
    disableEdits,
    disableRoleChecking,
  });

  return (
    <div className="e-card" id="basic" style={{ overflow: "visible" }}>
      <div className="e-card-content" style={{ overflow: "visible" }}>
        <div className="dashboard-title e-card-title">Relevant Documents</div>
        <hr />
        <div style={{ height: "70vh" }}>{useVendorReviewDocumentsHook.display}</div>
      </div>
    </div>
  );
};
export default withOrganizationCheck(UploadRelevantDocuments);
