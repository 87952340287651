import React, { useEffect, useState } from "react";

import { ItemMutation } from "@rivial-security/appsync-utils";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useForm } from "../../../../hooks/views/useForm";
import { useToolDataGrid } from "../../../Tools/Tool/hooks/useToolDataGrid";
import { AutoFetchForm } from "../components/AutoFetchForm";

/**
 * Form for creating a new Metric Type resource
 * @param {string} organizationID - organization id of current selected organization
 * @param {string[]} fields - fields to use for mutation
 * @param {object} nestedFields - nested fields to use for mutation
 * @param {function} toggleModal - callback to toggle the modal if the form is part of one
 * @param {function} getNewItem - callback to get a new item once update completes
 * @param {function} resetFunction - callback to re-fetch parent data from the database on update
 * @param {function} callback - the callback to be called after the form is submitted
 * @param {object} item - the metric type to edit if this is an update form
 * @param {object} formConfig - props to pass down to the useForm hook
 * @return {{input: {}, submitDisabled: boolean, display: *, setInput: Function}}
 */
export const useCreateMetricType = ({
  organizationID,
  fields,
  nestedFields,
  toggleModal,
  getNewItem,
  resetFunction,
  callback,
  item = {},
  formConfig = {},
}) => {
  const module = modules.METRICS;
  const resource = resources.METRIC_TYPE;

  const typename = "MetricType";
  const fieldsInitial = ["name", "description"];

  const tools = useToolDataGrid({ organizationID });

  const platformAutomations = [
    { name: "customAutomation" },
    { name: "numberOfPastDueActions" },
    { name: "totalNumberOfControls" },
    { name: "compliancePercentage" },
    { name: "averageInherentRisk" },
    { name: "averageResidualRisk" },
    { name: "blueprintScore" },
  ];

  const automations = [...platformAutomations];

  const [fetchConfig, setFetchConfig] = useState({});

  const fieldConfig = {
    name: {
      label: "Name",
      required: true,
    },
    description: {
      label: "Description",
    },
    format: {
      label: "Format",
      defaultValue: "decimal",
      inputType: "dropdown",
      placeholder: "Select a Data Format..",
      tooltip:
        "The format of the metric data. Used to display the metric nicely on the front end, does not affect the metric gathering",
      dropdownConfig: {
        data: [
          { text: "Decimal", value: "decimal" },
          { text: "Dollar Amount", value: "dollar" },
          { text: "Percentage", value: "percent" },
          // {text: "JSON", value: "json"},
        ],
      },
    },
    calculationType: {
      label: "Calculation Type",
      defaultValue: "mostRecent",
      inputType: "dropdown",
      tooltip:
        "Determines how to process the metric data. By default the system will take the most recent metric instance.",
      placeholder: "Select the type of metric..",
      dropdownConfig: {
        data: [
          { text: "Most Recent", value: "mostRecent" },
          { text: "Average", value: "average" },
          // {text: "Maximum", value: "max"},
          // {text: "Minimum", value: "min"},
        ],
      },
    },
    enabled: {
      label: "Enable Automated Metric Gathering",
      inputType: "switch",
      defaultValue: false,
    },

    frequency: {
      label: "Frequency of Metric Gathering",
      defaultValue: item.frequency || "monthly",
      inputType: "dropdown",
      placeholder: "Select a frequency..",
      dropdownConfig: {
        data: [
          { text: "One Time", value: "once" },
          { text: "Daily", value: "daily" },
          { text: "Weekly", value: "weekly" },
          { text: "Twice a Month", value: "biWeekly" },
          { text: "Monthly", value: "monthly" },
          { text: "Quarterly", value: "quarterly" },
          { text: "Yearly", value: "yearly" },
        ],
      },
      isHidden: ({ input }) => {
        return input?.enabled === false;
      },
    },
    automation: {
      label: "Automation",
      inputType: "dropdown",
      tooltip: "Create a custom Automation or choose one pre-built by the development team.",
      defaultValue: item?.automation?.name,
      dropdownConfig: {
        data:
          automations &&
          automations.map((item) => {
            return {
              text: convertCamelCaseToSentence(item.name),
              value: item,
            };
          }),
      },
      isHidden: ({ input }) => {
        return input?.enabled === false;
      },
    },
    customAutomation: {
      label: "Custom Automation Configuration",
      inputType: "custom",
      tooltip: "Perform a simple GET request and parse the data",
      removeFromInput: true,
      customConfig: {
        component: (
          <AutoFetchForm fetchConfig={fetchConfig} setFetchConfig={setFetchConfig} tools={tools.data} item={item} />
        ),
      },
      isHidden: ({ input }) => {
        const automationName = formHook?.input?.automation?.name;
        let isPlatformAutomation =
          platformAutomations.findIndex((platformAutomation) => {
            return platformAutomation.name === automationName;
          }) !== -1;
        if (automationName === "customAutomation") {
          isPlatformAutomation = false;
        } else if (!automationName) {
          isPlatformAutomation = true;
        }
        const automationDisabled = input?.enabled === false;
        return automationDisabled || isPlatformAutomation;
      },
    },
  };

  const submitFunction = async (input) => {
    let mutation;
    const { createMutation, updateMutation } = generateGraphql(typename, fields || fieldsInitial, nestedFields);
    if (item?.id) {
      mutation = updateMutation;
    } else {
      mutation = createMutation;
    }

    let newInput = input;
    if (fetchConfig && Object.keys(fetchConfig).length > 0) {
      newInput = {
        ...input,
        toolID: fetchConfig?.toolID || "",
        automation: {
          name: fetchConfig.name,
          fetchConfig: {
            url: fetchConfig.url,
          },
          jsonPath: fetchConfig.jsonPath,
          exclusions: fetchConfig.exclusions,
          inclusions: fetchConfig.inclusions,
        },
      };
    }

    if (item?.id) {
      newInput.id = item.id;
    } else {
      newInput.ownerGroup = organizationID;
    }

    return await ItemMutation({
      mutation,
      input: newInput,
    });
  };

  const formHook = useForm({
    item, // if an item is passed in this is now an update form
    typename: "MetricType",
    organizationID,
    submitFunction,
    module,
    resource,
    toggleModal,
    resetFunction,
    getNewItem,
    callback,
    fieldConfig,
    ...formConfig,
  });

  // Update the 'toolID' and 'automation' config inputs when the form updates
  useEffect(() => {}, [fetchConfig]);

  return {
    ...formHook,
  };
};
