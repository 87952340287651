import type { FC, ReactElement } from "react";

import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ListQueryBy } from "@rivial-security/appsync-utils";
import { convertCamelCaseToSentence, isNonEmptyArray } from "@rivial-security/func-utils";
import { generateListByQueryGraphql } from "@rivial-security/generategraphql";
import { useEffect, useState } from "react";

import type { RiskControlCategory } from "@rivial-security/schema-types";

import { DestinationTypes } from "@views/OrganizationManager/Questionnaires/constants/destinationTypes";

interface QuestionnaireField {
  name: string;
}

interface Destination {
  name: string;
  id: string;
}

interface FieldMapping {
  type: string;
  destination: Destination;
}

interface QuestionnaireFieldMappingProps {
  questionnaireFields: QuestionnaireField[];
  fieldMapping: Record<string, FieldMapping>;
  setFieldMapping: React.Dispatch<React.SetStateAction<Record<string, FieldMapping>>>;
  organizationID: string;
}

interface HandleDestinationChangeProps {
  sourceField: string;
  destinationFieldName: string;
  controlCategories: RiskControlCategory[];
  setFieldMapping: React.Dispatch<React.SetStateAction<Record<string, FieldMapping>>>;
}

const fetchControlCategories = async (organizationID: string): Promise<RiskControlCategory[]> => {
  const query = generateListByQueryGraphql({
    typename: "RiskControlCategory",
    nestedFields: "items { id name }",
    index: {
      indexName: "riskControlCategoryByOwnerGroup",
    },
  });

  const data = await ListQueryBy<RiskControlCategory>({
    query,
    variables: { ownerGroup: organizationID },
  });
  return data.sort((a, b) => a.name.localeCompare(b.name));
};

const getDestinationFields = (type: string | undefined, controlCategories: RiskControlCategory[]) => {
  return type === DestinationTypes.KEY_RISK_INDICATOR || type === DestinationTypes.CONTROL_CATEGORY
    ? controlCategories
    : [];
};

const handleTypeChange = (
  sourceField: string,
  type: string,
  setFieldMapping: React.Dispatch<React.SetStateAction<Record<string, FieldMapping>>>,
) => {
  setFieldMapping((prev) => ({
    ...prev,
    [sourceField]: { ...prev[sourceField], type, destination: { name: "", id: "" } },
  }));
};

const handleDestinationChange = ({
  sourceField,
  destinationFieldName,
  controlCategories,
  setFieldMapping,
}: HandleDestinationChangeProps): void => {
  const selectedCategory = controlCategories.find((category) => category.name === destinationFieldName);
  if (selectedCategory) {
    setFieldMapping((prev) => ({
      ...prev,
      [sourceField]: {
        ...prev[sourceField],
        type: prev[sourceField]?.type || "",
        destination: { name: selectedCategory.name, id: selectedCategory.id },
      },
    }));
  }
};

const QuestionnaireFieldMapping: FC<QuestionnaireFieldMappingProps> = ({
  questionnaireFields,
  fieldMapping,
  setFieldMapping,
  organizationID,
}): ReactElement | boolean => {
  const [controlCategories, setControlCategories] = useState<RiskControlCategory[]>([]);

  useEffect(() => {
    if (organizationID) {
      void fetchControlCategories(organizationID).then(setControlCategories);
    }
  }, [organizationID]);

  return (
    isNonEmptyArray(questionnaireFields) && (
      <div>
        <hr />
        <Typography variant="h6" gutterBottom>
          Risk Assessment Mapping
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Questionnaire Question</TableCell>
                <TableCell>Destination Type</TableCell>
                <TableCell>Destination</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionnaireFields.map((sourceField) => (
                <TableRow key={sourceField.name}>
                  <TableCell>{sourceField.name}</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        value={fieldMapping[sourceField.name]?.type || ""}
                        onChange={(e) => handleTypeChange(sourceField.name, e.target.value, setFieldMapping)}
                        variant="outlined"
                      >
                        <MenuItem value={DestinationTypes.KEY_RISK_INDICATOR}>
                          {convertCamelCaseToSentence(DestinationTypes.KEY_RISK_INDICATOR)}
                        </MenuItem>
                        <MenuItem value={DestinationTypes.CONTROL_CATEGORY}>
                          {convertCamelCaseToSentence(DestinationTypes.CONTROL_CATEGORY)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    {fieldMapping[sourceField.name]?.type && (
                      <FormControl fullWidth>
                        <Select
                          value={fieldMapping[sourceField.name]?.destination?.name || ""}
                          onChange={(e) =>
                            handleDestinationChange({
                              sourceField: sourceField.name,
                              destinationFieldName: e.target.value,
                              controlCategories,
                              setFieldMapping,
                            })
                          }
                          variant="outlined"
                        >
                          {getDestinationFields(fieldMapping[sourceField.name]?.type, controlCategories).map(
                            (destination) => (
                              <MenuItem key={destination.id} value={destination.name}>
                                {destination.name}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  );
};

export default QuestionnaireFieldMapping;
